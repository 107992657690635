<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Links Breakdown
        </h2>

        <ejs-grid ref="lgrid" :dataSource="data" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick'
                  :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings">
            <e-columns>
                <e-column field="index" headerText="Index" text-Align="Left" width="200"></e-column>
                <e-column field="url" headerText="Link URL" text-Align="Left" width="700"></e-column>
                <e-column field="above" headerText="Above Fold" text-Align="Left" width="200"></e-column>
                <e-column field="below" headerText="Below Fold" text-Align="Left" width="200"></e-column>
                <e-column field="clicks" headerText="Clicks" text-Align="Left"></e-column>
                <e-column field="percent" headerText="%" text-Align="Left"></e-column>
            </e-columns>
            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Sum" field="above" :footerTemplate="sumTemplate"></e-column>
                        <e-column type="Sum" field="below" :footerTemplate="sumTemplate"></e-column>
                        <e-column type="Sum" field="clicks" :footerTemplate="sumTemplate"></e-column>
                        <e-column type="Sum" field="percent" :footerTemplate="percentTemplate"></e-column>
                    </e-columns>
                </e-aggregate>
                <e-aggregate>
                    <e-columns>
                        <e-column type="Custom" field="above" :footerTemplate="aboveTemplate" :customAggregate="customAggregate"></e-column>
                        <e-column type="Custom" field="below" :footerTemplate="belowTemplate" :customAggregate="customAggregate"></e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, ListBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";

import {GridPlugin, Page, PdfExport, ExcelExport, Sort, Group, Toolbar, Aggregate} from '@syncfusion/ej2-vue-grids';
import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(ListBoxPlugin);

export default {
    name: "clicks",
    props: ["user"],
    data: function () {
        return {
            temp: null,
            filters: {
                from: moment().startOf("day").toDate(),
                to: moment().endOf("day").toDate(),
                domain: "",
                ip: ""
            },
            data: null,
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
            sumTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{data.Sum}}</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            percentTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{Math.ceil(data.Sum) > 100 ? 100 : Math.ceil(data.Sum)}}%</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            aboveTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{data.Custom.above}}%</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
            belowTemplate: function() {
                return {
                    template: Vue.component('sumTemplate', {
                        template: `<span>{{data.Custom.below}}%</span>`,
                        data: function () {return {data: {data: {}}};}
                    })
                }
            },
        };
    },
    computed: {
        query() {
            let filters = Object.assign({}, this.temp);
            return new Query().addParams('filters', JSON.stringify(filters));
        }
    },
    created: function () {
        this.$root.preloader = false;
    },
    mounted() {
        this.filter()
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        customAggregate : function () {
            let gridData = this.$refs.lgrid.ej2Instances.dataSource;

            let totalSum = 0;
            let aboveSum = 0;
            let belowSum = 0;
            for (let i = 0; i < gridData.length; i++) {
                totalSum = totalSum + gridData[i]['clicks'];
                aboveSum = aboveSum + gridData[i]['above'];
                belowSum = belowSum + gridData[i]['below'];
            }

            return {above: Math.round((aboveSum/totalSum) * 100), below: Math.round((belowSum/totalSum) * 100)}
        },
        actionBegin(args) {
            if(args.requestType === "paging") window.scrollTo(0,0);
        },
        actionFailure(args) {
            if (args.error.error.status === 401) this.$auth.logout();
        },
        exportComplete() {
            this.$refs.grid.hideSpinner();
        },
        toolbarClick: function(args = ClickEventArgs) {

            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    this.$refs.grid.showSpinner();
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    this.$refs.grid.showSpinner();
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties: function() {
            return {
                fileName: "qso_export.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: "linkReport.pdf"
            };
        },
        windowListener: function (e) {
            if (e.keyCode === 13 && !this.processing) {
                // this.load();
            }
        },
        exportToExcel: function () {
            // this.$root.preloader = true;
            //Load the catchers
            var request = Object.assign({ apikey: this.$root.apikey }, this.form);

            this.eLoading = true;
            this.preloader = this.$root.preloader = true;

            return axios
                .get(`${this.$root.serverUrl}/admin/reports/emailverifier/link-breakdown/${this.$route.params.id}`, {
                    params: request,
                    json: true,
                })
                .then(async (resp) => {
                    if (resp == undefined || resp.length == 0) {
                        // this.datasource = { total: 0, data: [] };
                        this.eLoading = false;
                        this.preloader = this.$root.preloader = false;
                    } else {
                        //request = this.serialize(resp);
                        //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
                        //this.eLoading = false;
                        // this.preloader = this.$root.preloader = false;
                    }
                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                });
        },
        filter() {
            this.$nextTick(function () {
                if (!this.data) {
                    this.data = new DataManager({
                        url: `${this.$root.serverUrl}/admin/reports/emailverifier/link-breakdown/${this.$route.params.id}`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })
                }
                let filters = Object.assign({}, this.filters);
                this.temp = filters;
            })
        }
    },
    provide: {
        grid: [Page, Sort,Group,Toolbar,PdfExport,ExcelExport,Aggregate]
    },
};
</script>

<style>
.e-spin-show {
    background: black;
    opacity: 0.5;
}
.e-spinner-inner {
    top: 20% !important;
}
.e-spin-material {
    height: 60px !important;
    width: 60px !important;
    transform-origin: 30px 30px 30px !important;
}
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-base/styles/material.css';
</style>
