<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Scraper Results
        </h2>

        <div class="row">
            <div class="col-md-12">
                <table class="table table-striped table-hover mt-3">
                    <thead>
                        <tr>
                            <th scope="col" v-for="(column, index) in columns" :key="index">{{ column.charAt(0).toUpperCase() + column.slice(1) }}</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(row, index) in data.result" :key="index">
                            <td v-for="(column, indx) in columns" :key="indx">{{ typeof row[column] === 'object' ? row[column] : row[column] }}</td>
                            <td>
                                <div class="d-flex" style="gap: 5px;">
                                    <button  data-toggle='modal'
                                             data-target='#viewModal' class="btn btn-sm btn-secondary" @click="view(row)"
                                    ><i class="fa fa-eye" title="Delete"></i></button>
                                    <button class="btn btn-sm btn-danger" @click="remove(row)"
                                    ><i class="fa fa-trash" title="Delete"></i></button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-sm-9">
                <v-pagination
                    v-model="page"
                    :pageCount="Math.ceil(data.count / max)"
                    :classes="bootstrapPaginationClasses"
                    :labels="customLabels"
                    :length="12"
                    :total-visible="7"
                    @input="load"
                ></v-pagination>
            </div>
            <div class="col-12 col-sm-3 text-right">
                <span>{{ data.result.length * Number(page | 1) }}</span> of
                <span>{{ data.count }}</span>
            </div>
        </div>

        <ModalView v-if="item" :item="item" :id="id" :votes="votes" :config="config" @close="item = null;"/>
    </div>
</template>

<script>

import axios from "axios";
import swal from "sweetalert";
import Vue from "vue";
import {GridPlugin, Group, Page, Sort} from "@syncfusion/ej2-vue-grids";
Vue.use(GridPlugin);
import ModalView from '../components/scraper/results'
import vPagination from "vue-plain-pagination";

export default {
    name: "ConfigResults",
    provide: {
        grid: [Page, Sort, Group]
    },
    components: { ModalView, vPagination },
    data() {
        return {
            id: null,
            config: null,
            item: null,
            votes: [],
            data: {result: []},
            columns: [],
            column_types: [],
            page: 1,
            max: 25,
            bootstrapPaginationClasses: {
                ul: "pagination",
                li: "page-item",
                liActive: "active",
                liDisable: "disabled",
                button: "page-link",
            },
            customLabels: {
                first: "First",
                prev: "Previous",
                next: "Next",
                last: "Last",
            },
        }
    },
    mounted() {
        this.search()

        let request = {};
        axios.get(`${this.$root.serverUrl}/admin/scraper/config/${this.$route.query.config}`, {params: request}).then((resp) => {
            this.config = resp.data.data;

            this.$root.preloader = false;
        }).catch((err) => {
            swal({title: "Error", text: err, icon: "error"});
        });
    },
    computed: {},
    methods: {
        view: function (data) {
            this.item = data.results;
            this.id = data._id;
            this.config_id = data.config_id;

            if (data.votes && data.votes.length) this.votes  = data.votes;
            else this.votes = [];
        },
        remove: function (row) {
            //Handle removing the item
            swal({
                title: "Remove",
                text: "Are you sure?",
                icon: "info",
                buttons: ["No", "Yes"],
            }).then((val) => {
                if (!val) return;

                var request = {apikey: this.$root.apikey, id: row._id};
                // this.$root.preloader = true;

                axios({
                    url: `${this.$root.serverUrl}/admin/scraper/config/result/${row._id}`,
                    data: request,
                    responseType: "json",
                    method: "DELETE",
                })
                    .then((resp) => {
                        //Process the results
                        this.$root.preloader = false;
                        if (resp.data && !resp.data.error) {
                            //Saved successfully, now update the parent
                            swal({
                                title: "Remove",
                                text: "Your request was completed successfully",
                                icon: "success",
                            }).then(
                                function () {
                                    this.search()
                                }.bind(this)
                            );
                        } else {
                            //Handle errors
                            swal({
                                title: "Remove",
                                text: resp.data.error.message,
                                icon: "error",
                            });
                        }
                    })
                    .catch((err) => {
                        this.$root.preloader = false;
                        this.errormsg = err.message || "An unexpected error occurred";
                        swal({title: "Error", text: this.errormsg, icon: "error"});
                    });
            })
        },
        search() {
            let request = {$skip: (this.page - 1) * this.max, $top: this.max, filters: {config_id: this.$route.query.config}};
            axios.get(`${this.$root.serverUrl}/admin/scraper/config/results`, {params: request}).then((resp) => {

                this.columns = ['date']
                let data = []
                for (let i = 0; i < resp.data.Items.length; i++) {
                    for (const key in resp.data.Items[i].data) {
                        if (!this.columns.includes(key)) this.columns.push(key)
                    }
                    data.push({date: resp.data.Items[i].created_ts, config_id: resp.data.Items[i].config_id, votes: resp.data.Items[i].votes, _id: resp.data.Items[i]._id, ...resp.data.Items[i].data, results: resp.data.Items[i].data})
                }

                this.data = {result: data, count: resp.data.Count};

                window.scroll(0, 0)
                this.$root.preloader = false;
            }).catch((err) => {
                swal({title: "Error", text: err, icon: "error"});
            });
        },
        load: function() {
            this.page = this.page < 1 ? 1 : this.page;
            this.search()
        }
    }
};
</script>

<style scoped>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
