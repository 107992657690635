import Vue from "vue"
import Vuex from 'vuex'
import axios from "axios"
import { SERVER_URL } from './constants'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        dateRange: null,
        user: null,
        domain: null,
        report: [],
        settings: { providers: [] },
        currentDay : [
            {
                "%ofClicks":10.48,
                "shownRate":32.38,
                "dayOfWeek":'Monday',
                "monthName":"June",
            },
            {
                "%ofClicks":11.48,
                "shownRate":32.38,
                "dayOfWeek":'Tuesday',
                "monthName":"June",
            },
            {
                "%ofClicks":10.48,
                "shownRate":32.38,
                "dayOfWeek":'Wednesday',
                "month":"June",
            },
            {
                "%ofClicks":11.48,
                "shownRate":32.38,
                "dayOfWeek":'Thursday',
                "month":"June",
            },
            {
                "%ofClicks":11.48,
                "shownRate":32.38,
                "dayOfWeek":'Friday',
                "month":"June",
            },
            {
                "%ofClicks":10.48,
                "shownRate":32.38,
                "dayOfWeek":'Saturday',
                "month":"June",
            },
            {
                "%ofClicks":11.48,
                "shownRate":32.38,
                "dayOfWeek":'Sunday',
                "month":"June",
            },
            {
                "%ofClicks":10.48,
                "shownRate":32.38,
                "dayOfWeek":'Monday',
                "month":"May",
            },
            {
                "%ofClicks":11.48,
                "shownRate":32.38,
                "dayOfWeek":'Tuesday',
                "month":"May",
            },
            {
                "%ofClicks":10.48,
                "shownRate":32.38,
                "dayOfWeek":'Wednesday',
                "month":"May",
            },
            {
                "%ofClicks":11.48,
                "shownRate":32.38,
                "dayOfWeek":'Thursday',
                "month":"May",
            },
            {
                "%ofClicks":11.48,
                "shownRate":32.38,
                "dayOfWeek":'Friday',
                "month":"May",
            },
            {
                "%ofClicks":10.48,
                "shownRate":32.38,
                "dayOfWeek":'Saturday',
                "month":"May",
            },
            {
                "%ofClicks":11.48,
                "shownRate":32.38,
                "dayOfWeek":'Sunday',
                "month":"May",
            }],
        actuals: [
            {
                "Hours":"0-1",
                "Impression": 642418,
                "ShownImpression": 12345,
                "%ActualShownImpression":'38.54%',
                "EstimatedHourlyImpression":4758,
                "%EstimatedShownImpression":"57%",
            },
            {
                "Hours":"1-2",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"2-3",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"3-4",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"4-5",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"5-6",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"7-8",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"8-9",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"9-10",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"10-11",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"11-12",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"12-13",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"13-14",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"14-15",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"15-16",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"16-17",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"17-18",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"18-19",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"19-20",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"20-21",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"21-22",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"22-23",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },
            {
                "Hours":"23-0",
                "Impression": '',
                "ShownImpression": '',
                "%ActualShownImpression":'',
                "EstimatedHourlyImpression":'',
                "%EstimatedShownImpression":"",
            },

        ],
        forcated:[
            {
                "Impression":"2022-07-04",
                "Hours":"Today",
                "Goals":'',
            },  {

                "Impression":"01:00 PM",
                "Hours":"Times (EST)",
                "Goals":'',
            },  {

                "Impression":"31",
                "Hours":"Today Days in Month",
                "Goals":'',
            },  {

                "Impression":"20",
                "Hours":"Days Left in Month",
                "Goals":'',
            },{

                "Impression":"11",
                "Hours":"Days Past in Month",
                "Goals":'',
            },{

                "Impression":"14,950,890",
                "Hours":"Forecasted Clicks",
                "Goals":'',
            },{

                "Impression":"482,278",
                "Hours":"Estimated Clicks Per Day",
                "Goals":'',
            },
            {

                "Impression":"2,123,123",
                "Hours":"Clicks Delivered So Far",
                "Goals":'',
            },
            {

                "Impression":"30%",
                "Hours":"% of Monthly Adretreaver Goal",
                "Goals":'',
            },
            {

                "Impression":"22.94%",
                "Hours":"Clicks Delivered % of SI Totals",
                "Goals":'40%',
            },
            {

                "Impression":'12,848,368',
                "Hours":"Estimated Clicks left in Month",
                "Goals":'',
            },
            {

                "Impression": 642418,
                "Hours":"Estimated Clicks left per day to hit goal",
                "Goals":'',
            },
        ],
        seriesDataDay: [
            {hour: 1, shown: 90, y1: 40},
            {hour: 2, shown: 10, y1: 90},
            {hour: 3, shown: 10, y1: 90},
            {hour: 4, shown: 1, y1: 12},
            {hour: 5, shown: 10, y1: 67},
            {hour: 6, shown: 10, y1: 90},
            {hour: 7, shown: 0, y1: 90},
            {hour: 8, shown: 10, y1: 99},
            {hour: 9, shown: 34, y1: 98},
            {hour: 10, shown: 10, y1: 55},
            {hour: 11, shown: 67, y1: 66},
            {hour: 12, shown: 10, y1: 100},
            {hour: 13, shown: 10, y1: 24},
            {hour: 14, shown: 90, y1: 90},
            {hour: 15, shown: 10, y1: 87},
            {hour: 16, shown: 10, y1: 58},
            {hour: 17, shown: 41, y1: 69},
            {hour: 18, shown: 36, y1: 10},
            {hour: 19, shown: 10, y1: 90},
            {hour: 20, shown: 10, y1: 90},
            {hour: 21, shown: 1, y1: 9},
            {hour: 22, shown: 11, y1: 20},
            {hour: 23, shown: 4, y1: 12},
        ],
        seriesDataMonth: [
            { x: "January", y: 90, y1: 40 },
            { x: "February", y: 10, y1: 90 },
            { x: "March", y: 10, y1: 90 },
            { x: "April", y: 1, y1: 12 },
            { x: "May", y: 10, y1: 67 },
            { x: "June", y: 10, y1: 99 },
            { x: "July", y: 10, y1: 55 },
            { x: "August", y: 67, y1: 66 },
            { x: "September", y: 10, y1: 100 },
            { x: "October", y: 10, y1: 90 },
            { x: "November", y: 41, y1: 69 },
            { x: "December", y: 1, y1: 9 },
        ],
        forecasted_clicks: [
            { month: 'January', forecasted_clicks: 12744567 },
            { month: 'February', forecasted_clicks: 19700134 },
            { month: 'March', forecasted_clicks: 19900134 },
            { month: 'April', forecasted_clicks: 12709934 },
            { month: 'May', forecasted_clicks: 10090134 },
            { month: 'June', forecasted_clicks: 14500134 },
            { month: 'July', forecasted_clicks: 12700134 },
            { month: 'August', forecasted_clicks: 12700134 },
            { month: 'Septemeber', forecasted_clicks: 15790134 },
            { month: 'October', forecasted_clicks: 12700134 },
            { month: 'November', forecasted_clicks: 16700134 },
            { month: 'December', forecasted_clicks: 20700134 }
        ],
        dataUsageData: [],
        impressionData: [
            { time: '00', provider1: 50, provider2: 70, provider3: 45 },
            { time: '01', provider1: 5, provider2: 67, provider3: 45 },
            { time: '02', provider1: 50, provider2: 70, provider3: 45 },
            { time: '03', provider1: 50, provider2: 10, provider3: 34 },
            { time: '04', provider1: 78, provider2: 70, provider3: 49 },
            { time: '05', provider1: 50, provider2: 87, provider3: 45 },
            { time: '06', provider1: 46, provider2: 70, provider3: 4 },
            { time: '07', provider1: 50, provider2: 90, provider3: 45 },
            { time: '08', provider1: 44, provider2: 30, provider3: 91 },
            { time: '09', provider1: 50, provider2: 23, provider3: 19 },
            { time: '10', provider1: 67, provider2: 70, provider3: 45 },
            { time: '11', provider1: 50, provider2: 44, provider3: 67 },
            { time: '12', provider1: 23, provider2: 23, provider3: 45 },
            { time: '13', provider1: 50, provider2: 78, provider3: 45 },
            { time: '14', provider1: 50, provider2: 70, provider3: 79 },
            { time: '15', provider1: 76, provider2: 99, provider3: 45 },
            { time: '16', provider1: 50, provider2: 12, provider3: 45 },
            { time: '17', provider1: 50, provider2: 14, provider3: 45 },
            { time: '18', provider1: 50, provider2: 48, provider3: 98 },
            { time: '19', provider1: 27, provider2: 70, provider3: 44 },
            { time: '20', provider1: 50, provider2: 70, provider3: 45 },
            { time: '21', provider1: 90, provider2: 73, provider3: 45 },
            { time: '22', provider1: 78, provider2: 57, provider3: 99 },
            { time: '23', provider1: 55, provider2: 70, provider3: 5 },
        ],
        dayDataUsageData: [],
        bouncedWebsites: [
            // { client: 'Site Impact', domain: 'https://google.com', impressionns: 23, bounce_count: 12, bounce_time: '13:12' },
            // { client: 'Site Impact', domain: 'https://msn.com', impressionns: 20, bounce_count: 3, bounce_time: '13:30' },
            // { client: 'Site Impact', domain: 'https://bing.com', impressionns: 56, bounce_count: 1, bounce_time: '13:45' },
        ],
        userAgents: [
            // { user_agent: 'Mac OS', impressions: 23, total_percentage: 12 },
        ],
        lowTOS: [
            // { client: 'Site Impact', domain: 'https://google.com', tos: 3.4, reason: 'N/A' },
            // { client: 'Site Impact', domain: 'https://msn.com', tos: 2, reason: 'N/A' },
            // { client: 'Site Impact', domain: 'https://bing.com', tos: 5, reason: 'N/A' },
        ],

        websiteError:[
                {
                    "_id": "63334127a9c39824d1ff4094",
                    "numberOfImpression": 40,
                    "numberOffails": 24,
                    "percentageOfFails": 60,
                    "name": "Craig",
                    "domain": "www.Craigcom",
                    "script": "www.ga-Craig.com",
                    "Date": "2019-01-16T02:13:33 +05:00",
                    "errorList": [
                        "occaecat",
                        "nulla",
                        "eu",
                        "amet",
                        "anim",
                        "minim",
                        "dolor",
                        "quis",
                        "aliqua"
                    ],
                    "summary": [
                        {
                            "scripts": "www.irure.com",
                            "Date": "2018-07-23T05:39:30 +05:00",
                            "domain": "www.undefined.com",
                            "error": "sint"
                        },
                        {
                            "scripts": "www.anim.com",
                            "Date": "2021-03-23T03:14:34 +05:00",
                            "domain": "www.undefined.com",
                            "error": "ut"
                        },
                        {
                            "scripts": "www.commodo.com",
                            "Date": "2018-10-22T11:17:27 +05:00",
                            "domain": "www.undefined.com",
                            "error": "anim"
                        },
                        {
                            "scripts": "www.id.com",
                            "Date": "2019-02-11T05:23:31 +05:00",
                            "domain": "www.undefined.com",
                            "error": "Lorem"
                        },
                        {
                            "scripts": "www.qui.com",
                            "Date": "2019-12-31T02:32:11 +05:00",
                            "domain": "www.undefined.com",
                            "error": "Lorem"
                        },
                        {
                            "scripts": "www.ad.com",
                            "Date": "2016-12-28T04:14:54 +05:00",
                            "domain": "www.undefined.com",
                            "error": "consequat"
                        },
                        {
                            "scripts": "www.consectetur.com",
                            "Date": "2019-03-26T05:09:05 +05:00",
                            "domain": "www.undefined.com",
                            "error": "pariatur"
                        }
                    ]
                },
                {
                    "_id": "6333412701eef3ceccdde7be",
                    "numberOfImpression": 25,
                    "numberOffails": 20,
                    "percentageOfFails": 80,
                    "name": "Lora",
                    "domain": "www.Loracom",
                    "script": "www.ga-Lora.com",
                    "Date": "2022-05-19T06:15:39 +05:00",
                    "errorList": [
                        "aliquip",
                        "ea",
                        "nulla",
                        "veniam",
                        "sit"
                    ],
                    "summary": [
                        {
                            "scripts": "www.velit.com",
                            "Date": "2016-07-31T02:24:57 +05:00",
                            "domain": "www.undefined.com",
                            "error": "esse"
                        },
                        {
                            "scripts": "www.officia.com",
                            "Date": "2016-12-19T07:40:32 +05:00",
                            "domain": "www.undefined.com",
                            "error": "id"
                        },
                        {
                            "scripts": "www.sit.com",
                            "Date": "2019-07-12T11:04:27 +05:00",
                            "domain": "www.undefined.com",
                            "error": "fugiat"
                        },
                        {
                            "scripts": "www.tempor.com",
                            "Date": "2014-03-01T05:19:52 +05:00",
                            "domain": "www.undefined.com",
                            "error": "est"
                        },
                        {
                            "scripts": "www.nulla.com",
                            "Date": "2021-08-13T10:46:45 +05:00",
                            "domain": "www.undefined.com",
                            "error": "anim"
                        },
                        {
                            "scripts": "www.voluptate.com",
                            "Date": "2018-07-12T11:39:49 +05:00",
                            "domain": "www.undefined.com",
                            "error": "quis"
                        },
                        {
                            "scripts": "www.minim.com",
                            "Date": "2021-04-27T12:43:40 +05:00",
                            "domain": "www.undefined.com",
                            "error": "labore"
                        }
                    ]
                },
                {
                    "_id": "6333412767cca426707b4f53",
                    "numberOfImpression": 38,
                    "numberOffails": 25,
                    "percentageOfFails": 65.78947368421053,
                    "name": "Mckay",
                    "domain": "www.Mckaycom",
                    "script": "www.ga-Mckay.com",
                    "Date": "2021-02-03T07:20:51 +05:00",
                    "errorList": [
                        "laborum",
                        "velit",
                        "officia",
                        "est",
                        "est"
                    ],
                    "summary": [
                        {
                            "scripts": "www.eiusmod.com",
                            "Date": "2018-01-18T01:35:49 +05:00",
                            "domain": "www.undefined.com",
                            "error": "laborum"
                        },
                        {
                            "scripts": "www.ut.com",
                            "Date": "2018-11-02T05:06:09 +05:00",
                            "domain": "www.undefined.com",
                            "error": "et"
                        },
                        {
                            "scripts": "www.dolore.com",
                            "Date": "2022-01-22T03:55:37 +05:00",
                            "domain": "www.undefined.com",
                            "error": "aliquip"
                        },
                        {
                            "scripts": "www.amet.com",
                            "Date": "2022-08-14T10:28:09 +05:00",
                            "domain": "www.undefined.com",
                            "error": "ullamco"
                        },
                        {
                            "scripts": "www.fugiat.com",
                            "Date": "2017-06-03T03:28:54 +05:00",
                            "domain": "www.undefined.com",
                            "error": "minim"
                        },
                        {
                            "scripts": "www.magna.com",
                            "Date": "2015-12-10T12:31:42 +05:00",
                            "domain": "www.undefined.com",
                            "error": "aliquip"
                        },
                        {
                            "scripts": "www.do.com",
                            "Date": "2015-12-14T12:44:03 +05:00",
                            "domain": "www.undefined.com",
                            "error": "dolor"
                        }
                    ]
                },
                {
                    "_id": "63334127af6daed2ce13589a",
                    "numberOfImpression": 36,
                    "numberOffails": 25,
                    "percentageOfFails": 69.44444444444444,
                    "name": "Berry",
                    "domain": "www.Berrycom",
                    "script": "www.ga-Berry.com",
                    "Date": "2020-03-21T01:16:29 +05:00",
                    "errorList": [
                        "occaecat",
                        "occaecat",
                        "ipsum",
                        "amet",
                        "reprehenderit",
                        "irure",
                        "in",
                        "aute",
                        "eu",
                        "cupidatat"
                    ],
                    "summary": [
                        {
                            "scripts": "www.exercitation.com",
                            "Date": "2022-01-14T02:39:22 +05:00",
                            "domain": "www.undefined.com",
                            "error": "duis"
                        },
                        {
                            "scripts": "www.pariatur.com",
                            "Date": "2021-05-19T07:15:15 +05:00",
                            "domain": "www.undefined.com",
                            "error": "ex"
                        },
                        {
                            "scripts": "www.ea.com",
                            "Date": "2015-01-26T01:38:58 +05:00",
                            "domain": "www.undefined.com",
                            "error": "est"
                        },
                        {
                            "scripts": "www.dolore.com",
                            "Date": "2014-09-07T03:25:22 +05:00",
                            "domain": "www.undefined.com",
                            "error": "officia"
                        },
                        {
                            "scripts": "www.labore.com",
                            "Date": "2020-12-24T02:12:22 +05:00",
                            "domain": "www.undefined.com",
                            "error": "commodo"
                        },
                        {
                            "scripts": "www.aliquip.com",
                            "Date": "2020-09-30T02:05:39 +05:00",
                            "domain": "www.undefined.com",
                            "error": "officia"
                        },
                        {
                            "scripts": "www.laborum.com",
                            "Date": "2019-12-15T01:05:11 +05:00",
                            "domain": "www.undefined.com",
                            "error": "consequat"
                        }
                    ]
                },
                {
                    "_id": "633341276115ef06a95db9a1",
                    "numberOfImpression": 40,
                    "numberOffails": 18,
                    "percentageOfFails": 45,
                    "name": "Lydia",
                    "domain": "www.Lydiacom",
                    "script": "www.ga-Lydia.com",
                    "Date": "2017-09-22T05:50:13 +05:00",
                    "errorList": [
                        "magna",
                    ],
                    "summary": [
                        {
                            "scripts": "www.et.com",
                            "Date": "2014-07-25T08:23:03 +05:00",
                            "domain": "www.undefined.com",
                            "error": "magna"
                        }
                    ]
                }

        ],
        reportFilters:{},
        currentTab: 0,
        v: {},

    },
    mutations: {

        setUser (state) {
            return axios.get(`${SERVER_URL}/admin/profile`).then((resp) => {
                if(resp.data && resp.data.data) {
                    state.user = resp.data.data;
                }
            }).catch(function (err) {
                console.error(err.message);
            });
        },
        setSettings (state) {
            return axios.get(`${SERVER_URL}/admin/settings`).then((resp) => {
                if(resp.data && resp.data.data) {
                    state.settings = resp.data.data.settings;
                }
            }).catch(function (err) {
                console.error(err.message);
            });
        },

        setDomainConfig (state, config) {
            state.domain.config = config;
        },
        setDateRange (state, range) {
            state.dateRange = range;
        },
        setDomain (state, domain) {
            state.domain = domain;
        },
        setReport (state, report) {
            state.report = report;
        },
        setForcated (state, data) {
            state.forcated = data;
        },
        setActuals (state, data) {
            state.actuals = data;
        },
        setCurrentDay (state, data) {
            state.currentDay = data;
        },
        setSeriesDataDay (state, data) {
            state.seriesDataDay = data;
        },
        setSeriesDataMonth (state, data) {
            state.seriesDataMonth = data;
        },
        removeUser (state) {
            state.user = null;
        },
        setDataUsageData (state, data) {
            state.dataUsageData = data;
        },
        setDataUsageDataDay (state, data) {
            state.dayDataUsageData = data;
        },
        setImpressionData (state, data) {
            state.impressionData = data;
        },
        setBouncedWebsitesData (state, data) {
            state.bouncedWebsites = data;
        },
        setLowTosWebsitesData (state, data) {
            state.lowTOS = data;
        },
        setUserAgentsData (state, data) {
            state.userAgents = data;
        },
        setReportData(state,data){
            state.reportFilters = data
        },

    },
    actions: {
        setUser ({ commit }) {
            commit('setUser')
        },
        setSettings ({ commit }) {
            commit('setSettings')
        },
        setDomain ({ commit }) {
            commit('setDomain')
        },
        removeUser ({ commit }) {
            commit('removeUser')
        }
    }
})
