<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Scraper Configuration
        </h2>

        <div class="mb-4 d-flex justify-content-end">
            <button class="btn btn-sm btn-primary" data-toggle='modal' data-target='#configEditModal' @click="newConfig">
                <span class="fa fa-plus" title="new"></span> New</button>
        </div>
        <div class="widget-content widget-content-area component-card_1">
            <ejs-grid ref="grid" :dataSource="data"
                      :isResponsive="true" :allowPaging="true" :allowFiltering="true" :allowSorting="true"
                      :pageSettings="pageSettings" :actionBegin="actionBegin">
                <e-columns>
                    <e-column field="name" headerText="Name" text-Align="Left" :isPrimaryKey='true' width="240"></e-column>
                    <e-column field="url" headerText="Domain" text-Align="Left" :isPrimaryKey='true' width="160"></e-column>
                    <e-column field="frequency" headerText="Frequency" text-Align="Left" :isPrimaryKey='true' width="160"></e-column>
                    <e-column field="status" headerText="Status" text-Align="Left" :isPrimaryKey='true' width="160"></e-column>
                    <e-column :template="datetemplate" headerText="Last Visit" text-Align="Left" :isPrimaryKey='true' width="160"></e-column>
                    <e-column :template="actionTemplate" width="180"></e-column>
                </e-columns>
            </ejs-grid>

            <EditModal v-if="item" :item="item" :save="save" :update="update" />
        </div>
    </div>
</template>

<script>

import Vue from "vue";
import EditModal from '../components/scraper/edit'

import { GridPlugin, Page, Sort} from '@syncfusion/ej2-vue-grids';
import swal from "sweetalert";
import axios from "axios";
import moment from "moment-timezone";
Vue.use(GridPlugin);

let Bus = new Vue();

export default {
    name: "ScraperConfig",
    props: [
        "user"
    ],
    provide: {
        grid: [Page, Sort]
    },
    created: function () {
        Bus.$on("results", (data) => {
            this.config = data;
        });

        Bus.$on("edit", (data) => {
            this.item = data;
        });

        Bus.$on("delete", () => {
            this.search()
        })
    },
    mounted() {
        this.search()
    },
    components: {EditModal},
    data() {
        return {
            data: null,
            item: null,
            config: null,
            page: 1,
            filters: { search: '' },
            pageSettings: {
                pageSize: 25,
                pageSizes: [25, 50, 100, 250, 500, 1000],
                currentPage: 1,
                totalRecordsCount: 127
            },
            datetemplate: function () {
                return {
                    template: Vue.component('dateTemplate', {
                        template: `
                            <div id="dateTemplate" class="bg-active">
                              <span v-if="data.last_visit">{{timeconversion(data.last_visit,this.$store.state.user.timezone)}}</span>
                              <span v-else>N/A</span>
                            </div>`,
                        data: function () { return { data: {}}; },  methods:{

                            timeconversion : (date,timezone) =>{
                                if(date == undefined){
                                    return 'undefined'
                                }else{
                                    return  moment(date).tz(timezone).format('YYYY-MM-DD')
                                }

                            } ,
                        }
                    })
                }
            },
            actionTemplate: function () {
                return {
                    template: Vue.component('actionTemplate', {
                            template: `
                              <div class="d-flex" style="gap: 5px;">
                              <a
                                     class="btn btn-sm btn-secondary" :href="link" target="_blank"
                                ><i class="fa fa-eye" title="results"></i></a>
                               <button
                                    data-toggle='modal'
                                    data-target='#configEditModal'
                                    class="btn btn-sm btn-secondary"
                                    @click="details"
                                ><i class="fa fa-pencil" title="edit"></i></button>
                                <button class="btn btn-sm btn-danger" @click="remove"
                                ><i class="fa fa-trash" title="Delete"></i></button>
                            </div>
                            `,
                            data: function () {
                                return {
                                    data: {}
                                }
                            },
                            computed: {
                                link() {
                                    return `/scraper/result?config=${this.data._id}`
                                }
                            },
                            methods: {
                                results() {
                                    Bus.$emit("results", this.data)
                                },
                                details() {
                                    Bus.$emit("edit", this.data)
                                },
                                remove: function () {
                                    //Handle removing the item
                                    swal({
                                        title: "Remove",
                                        text: "Are you sure?",
                                        icon: "info",
                                        buttons: ["No", "Yes"],
                                    }).then((val) => {
                                        if (!val) return;

                                        var request = {apikey: this.$root.apikey, id: this.data._id};
                                        // this.$root.preloader = true;

                                        axios({
                                            url: `${this.$root.serverUrl}/admin/scraper/config/${this.data._id}`,
                                            data: request,
                                            responseType: "json",
                                            method: "DELETE",
                                        })
                                            .then((resp) => {
                                                //Process the results
                                                this.$root.preloader = false;
                                                if (resp.data && !resp.data.error) {
                                                    //Saved successfully, now update the parent
                                                    swal({
                                                        title: "Remove",
                                                        text: "Your request was completed successfully",
                                                        icon: "success",
                                                    }).then(
                                                        function () {
                                                            Bus.$emit("delete")
                                                        }.bind(this)
                                                    );
                                                } else {
                                                    //Handle errors
                                                    swal({
                                                        title: "Remove",
                                                        text: resp.data.error.message,
                                                        icon: "error",
                                                    });
                                                }
                                            })
                                            .catch((err) => {
                                                this.$root.preloader = false;
                                                this.errormsg = err.message || "An unexpected error occurred";
                                                swal({title: "Error", text: this.errormsg, icon: "error"});
                                            });
                                    })
                                }
                            }
                        }
                    )
                }
            },
        };
    },
    methods: {
        newConfig() {
          this.item = {}
        },
        search() {
            let request = {$skip: (this.page - 1) * this.pageSettings.pageSize, $top: this.pageSettings.pageSize, filters: {}};
            axios.get(`${this.$root.serverUrl}/admin/scraper/config`, {params: request}).then((resp) => {
                this.data = {result: resp.data.Items, count: resp.data.Count};
                this.$root.preloader = false;
            }).catch((err) => {
                console.log(err)
            });
        },
        async update() {
            axios.put(`${this.$root.serverUrl}/admin/scraper/config/`, {id: this.item._id, args: this.item}).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
                } else {
                    this.item = null;
                    this.search()
                    window.$('#configEditModal').modal('hide')
                }
            }).catch((err) => {
                console.log(err)
                swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
            })
        },
        async save() {
            axios.post(`${this.$root.serverUrl}/admin/scraper/config/`, {args: this.item}).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
                } else {
                    this.item = null;
                    this.search()
                }
                window.$('#configEditModal').modal('hide')
            }).catch((err) => {
                console.log(err)
                swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
            })
        },
        actionBegin: function(args) {
            if(args.requestType === "paging") {
                this.page = args.currentPage;
                this.search()
            }
        }
    }
};
</script>

<style>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
</style>
