<template>
    <!-- The Modal -->
    <div class="modal fade" id="configEditModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">Edit Configuration</h4>
                    <button type="button" class="close" data-dismiss="modal" @click="$emit('close')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body">
                    <div class="container-fluid">
                        <div class="row editor">
                            <div class="col-12 col-sm-12">
                                <div class="form" role="form" style="">
                                    <!-- section one -->
                                    <div class="row">
                                        <div class="col-12"><h5>Settings</h5><br></div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Name <strong class="text-danger">*</strong></label>
                                                <input type="text" v-model="item.name" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Handler <strong class="text-danger">*</strong></label>
                                                <multiselect v-model="item.handler" id="ajax" label="domain" track-by="domain" placeholder="Type to search" open-direction="bottom" :options="handlers" :multiple="false" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :close-on-search="false" :max-height="600" :show-no-results="false" :hide-selected="false" @search-change="onInput" @select="changeHandler">
                                                    <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.domain }}</strong></template>
                                                </multiselect>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Website <strong class="text-danger">*</strong></label>
                                                <input type="text" v-model="item.url" class="form-control" />
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Status <strong class="text-danger">*</strong></label>
                                                <select v-model="item.status" class="form-control text-capitalize required" required>
                                                    <option v-for="(status, index) in statuses" :key="index" :value="status">{{ status }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Frequency <strong class="text-danger">*</strong></label>
                                                <select v-model="item.frequency" class="form-control text-capitalize required" required>
                                                    <option v-for="(frequency, index) in frequencies" :key="index" :value="frequency">{{ frequency }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Date Range <strong class="text-danger">*</strong></label>
                                                <ejs-daterangepicker cssClass="e-outline" v-model="item.daterange" ></ejs-daterangepicker>
                                            </div>
                                        </div>

                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Proxy <strong class="text-danger">*</strong></label>
                                                <select v-model="item.proxy" @change="loadcountries" class="form-control text-capitalize required" required>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="item.proxy === 'yes'" class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Provider <strong class="text-danger">*</strong></label>
                                                <select v-model="item.proxy_provider" class="form-control">
                                                    <option selected value="">-- Any Provider --</option>
                                                    <option
                                                        v-for="(provider, index) in providers"
                                                        :value="provider.provider"
                                                        :key="index"
                                                    >
                                                        {{ provider.provider }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="item.proxy === 'yes'" class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Country <strong class="text-danger">*</strong></label>
                                                <select v-model="item.proxy_country" @change="loadstates" class="form-control">
                                                    <option value="" selected>-- Any Country --</option>
                                                    <option
                                                        v-for="country in countries"
                                                        :key="country._id"
                                                        :value="country.code"
                                                    >{{ country.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="item.proxy === 'yes' && item.proxy_country" class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>State <strong class="text-danger"></strong></label>
                                                <select v-model="item.proxy_state" @change="loadcities" class="form-control">
                                                    <option value="" selected>-- Any State --</option>
                                                    <option v-for="state in states" :key="state" :value="state">{{state}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="item.proxy === 'yes' && item.proxy_state" class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>City <strong class="text-danger"></strong></label>
                                                <select v-model="item.proxy_city" class="form-control">
                                                    <option value="" selected>-- Any City --</option>
                                                    <option v-for="city in cities" :key="city._id" :value="city.city_name">{{city.city_name}}</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Use ChatGPT <strong class="text-danger">*</strong></label>
                                                <select v-model="item.chatgpt" class="form-control text-capitalize required" required>
                                                    <option value="no">No</option>
                                                    <option value="yes">Yes</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="item.chatgpt === 'yes'" class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>ChatGPT Key <strong class="text-danger">*</strong></label>
                                                <select v-model="item.key" class="form-control">
                                                    <option
                                                        v-for="model in models"
                                                        :key="model.description"
                                                        :value="model.key"
                                                    >
                                                        {{ model.description }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div v-if="item.chatgpt === 'yes'" class="col-md-6 col-sm-12">
                                            <div class="form-group">
                                                <label>Chat Question <strong class="text-danger">*</strong></label>
                                                <select v-model="item.chat_question" class="form-control">
                                                    <option
                                                        v-for="feild in feilds"
                                                        :key="feild.name"
                                                        :value="feild.name"
                                                    >
                                                        {{ feild.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="item.prerequisite" class="mt-4 row">
                                        <div class="col-12"><h5>Search</h5><br></div>
                                    </div>

                                    <div v-if="item.prerequisite" class="row">
                                        <div class="col-12">
                                            <div v-if="item.prerequisite.type === 'list'">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-16">
                                                            <div class="d-flex mb-3" style="gap: 5px;">
                                                                <input type="text" v-model="item.prerequisite.temp" :placeholder="item.prerequisite.name" class="form-control" />
                                                                <button class="btn btn-sm btn-primary" @click="addP({text: item.prerequisite.temp})"><i class="fa fa-plus" title="add"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <input class="mb-3" type="file" @change="importList"/>

                                                <input v-if="item.prerequisite.value" class="e-input" type="text" v-model="search" placeholder="Filter" @keyup='onkeyup' />
                                                <ejs-listbox v-if="item.prerequisite.value" height="150" cssClass="e-listbox" ref="list" :dataSource='item.prerequisite.value' :itemTemplate="itemtemplate" placeholder="No zip added" :change="removeItem"></ejs-listbox>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="item.methods && item.methods.length" class="mt-5 row">
                                        <div class="col-12"><h5>Methods</h5><br></div>
                                    </div>

                                    <div v-if="item.methods && item.methods.length" class="row">
                                        <div v-for="method in item.methods" :key="method.name" class="col-md-12 mb-5">
                                            <div class="form-group mb-0">
                                                <label><strong>{{ method.name }}</strong></label>
                                                <input v-if="method.type === 'prerequisite'" type="text" v-model="method.prerequisite" readonly class="form-control" />
                                                <input v-if="method.type === 'string'" type="text" v-model="method.value" :placeholder="method.name" class="form-control" />
                                                <input v-if="method.type === 'number'" type="number" v-model="method.value" :placeholder="method.name" class="form-control" />
                                                <select v-if="method.type === 'select'" v-model="method.value" :placeholder="method.name" class="form-control text-capitalize required">
                                                    <option v-for="(opt, index) in method.options" :key="index" :value="opt">{{ opt }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="datapoints || item.datapoints" class="mt-4 row">
                                        <div class="col-12"><h5>Data Points</h5><br></div>
                                    </div>

                                    <div v-if="datapoints || item.datapoints" class="row">
                                        <div class="col-md-12">
                                            <ejs-multiselect
                                                :dataSource="datapoints"
                                                v-model="item.datapoints"
                                                placeholder="Select data points to scrape"
                                                cssClass="e-outline"
                                            ></ejs-multiselect>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <div class="row pt-4">
                        <div class="col-12">
                            <div class="form-group text-center">
                                <button v-if="!item._id" type="submit" class="btn btn-primary btn-sm"
                                        @click.prevent="save">
                                    <span class="glyphicon glyphicon-ok"></span> Create Configuration
                                </button>
                                <button v-else type="submit" class="btn btn-primary btn-sm"
                                        @click.prevent="update">
                                    <span class="glyphicon glyphicon-ok"></span> Update Configuration
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>

import Vue from 'vue';
import { DiagramPlugin,BpmnDiagrams,Diagram,SnapConstraints } from '@syncfusion/ej2-vue-diagrams';
import axios from "axios";
import swal from "sweetalert";
import { DataManager, Query } from "@syncfusion/ej2-data";
Diagram.Inject(BpmnDiagrams);
Vue.use(DiagramPlugin);
import Multiselect from 'vue-multiselect';

import { DateRangePickerPlugin } from '@syncfusion/ej2-vue-calendars';
Vue.use(DateRangePickerPlugin);

// register globally
Vue.component('multiselect', Multiselect)

let snapSettings = {
    // Display both Horizontal and Vertical gridlines
    constraints:  SnapConstraints.None
};

export default {
    name: "ConfigModal",
    props: [
        "item",
        "save",
        "update"
    ],
    components: { },
    data() {
        return {
            args: {},
            ready: false,
            isLoading: false,
            start: 50,
            method: "",
            search: "",
            selected: [],
            methods: [],
            models: [],
            providers: [],
            countries: [],
            states: [],
            cities: [],
            datapoints: null,
            statuses: ["active", "disabled"],
            frequencies: ["once", "hourly", "daily", "monthly"],
            handlers: [],
            width: "100%",
            height: "300px",
            itemtemplate: "<div class='list-wrapper'><span class='text'>${text}<span style='margin-left: 11px;' class='e-icons e-delete'></span></div>",
            saved: null,
            snapSettings: snapSettings
        }
    },
    mounted() {
      if (this.item.handler) {
          this.onInput(this.item.handler.domain);
          this.methods = this.item.handler.handler;

          for (let i = 0; i < this.methods.length; i++) {
              if (this.methods[i].items && this.methods[i].items.length) this.methods[i].datapoints = [];
          }
          if (!this.item.nodes) this.item.nodes = [];
      }
      this.loadKeys();
      this.getProviders();
      this.loadcountries();
      this.loadstates();
      this.loadcities()
    },
    computed: {},
    methods: {
        onkeyup () {
            let keyupvalue = this.search;
            this.saved = this.saved ? this.saved : this.item.prerequisite.value;
            let Filtereddata = new DataManager(this.item.prerequisite.value).executeLocal(new Query().where("text", "startswith", keyupvalue, true));

            this.item.prerequisite.value = null;
            if (!keyupvalue) {
                this.item.prerequisite.value = this.saved;
                this.saved = null;
            } else {
                this.item.prerequisite.value = Filtereddata;
            }
        },
        importList() {
            const file = event.target.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    console.log(e.target.result)
                    this.item.prerequisite.value = e.target.result.split(",").map((item) => {return {text: item}});
                    this.$forceUpdate();
                };
                reader.readAsText(file);
            }
        },
        addP(item) {
            this.item.prerequisite.temp = '';
            let items = [...this.item.prerequisite.value, item];
            this.item.prerequisite.value = null;
            this.item.prerequisite.value = items;
            this.$forceUpdate();
        },
        removeItem(args) {
            console.log(args)
            swal({title:"Remove", text:"Are you sure", icon:"info", buttons:['No','Yes']}).then((val) => {
                if (!val) return;
                this.item.prerequisite.value = this.item.prerequisite.value.filter(function(e) { return e.text !== args.value[0] })
            });
        },
        getProviders() {
            //Load the providers
            // this.$root.preloader = true;
            this.providers = [];

            axios.get(`${this.$root.serverUrl}/admin/proxies/providers`).then(function(resp){
                this.$root.preloader = false;
                this.providers = (resp && resp.data && resp.data.data) ? resp.data.data: [];
            }.bind(this));
        },
        loadcountries: function(){
            if(this.item.proxy != "yes") return;
            var request = {'apikey': this.$root.apikey,sSearch:''};

            return axios.get(`${this.$root.serverUrl}/admin/countries`, {params:request}).then(function(resp){
                //Store the stats
                this.countries = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadstates: function(){
            var request = {'apikey': this.$root.apikey, filters:{}};

            if(this.item.proxy_country) request.filters.country_iso_code = this.item.proxy_country;

            return axios.get(`${this.$root.serverUrl}/admin/geos/state`, {params:request}).then(function(resp){
                //Store the stats
                this.states = (resp.data && resp.data.data)? resp.data.data : [];
            }.bind(this));
        },
        loadcities: function(){
            var request = {'apikey': this.$root.apikey,filters:{}};

            if(this.item.proxy_country) request.filters.country_iso_code = this.item.proxy_country;
            if(this.item.proxy_state) request.filters.subdivision_1_iso_code = this.item.proxy_state;

            return axios.get(`${this.$root.serverUrl}/admin/geos/city`, {params:request}).then(function(resp){
                //Store the stats
                this.cities = (resp.data)? resp.data.data : [];
            }.bind(this));
        },
        loadKeys: function(){
            return axios.get(`${this.$root.serverUrl}/admin/chat/list/keys`).then((resp) => {
                this.models = (resp.data && resp.data.Items)? resp.data.Items : [];

                if (resp && resp.data && resp.data.error) {
                    swal({ title: "Error", text: "There was an error loading keys.", icon: "error" });
                }
                this.$root.preloader = false;
            }).catch((err) => {
                console.log(err)
                swal({ title: "Error", text: "There was an error loading keys.", icon: "error" });
            });
        },
        addMethod() {
            let diagramInstance;
            let diagramObj = document.getElementById("diagram");
            diagramInstance = diagramObj.ej2_instances[0];

            if (this.item.nodes.length) {
                let connector = {
                    type: "connector",
                    // Name of the connector
                    id: "connector" + this.item.nodes.filter((node) => node.type == "connector").length,
                    style: {
                        strokeColor: '#6BA5D7',
                        fill: '#6BA5D7',
                        strokeWidth: 2
                    },
                    targetDecorator: {
                        style: {
                            fill: '#6BA5D7',
                            strokeColor: '#6BA5D7'
                        }
                    },
                    // Sets source and target points
                    sourcePoint: {
                        x: 100 + ((this.item.nodes.filter((node) => node.type !== "connector").length-1) * 200),
                        y: 125
                    },
                    targetPoint: {
                        x: 200 * this.item.nodes.filter((node) => node.type !== "connector").length,
                        y: 125
                    }
                }
                diagramInstance.add(connector);
                this.item.nodes.push(connector);
            }

            let name = this.method.name;

            if (this.method.args && this.method.args.length){
                name += "\n" + this.method.args.map((arg) => arg.name + ": " + arg.value).join("\n")
            }

            if (this.method.datapoints && this.method.datapoints.length){
                name += "\n" + this.method.datapoints.join(", ")
            }

            let node = {
                id: "node" + this.item.nodes.filter((node) => node.type !== "connector").length,
                // Position of the node
                offsetX: this.start,
                offsetY: 125,
                // Size of the node
                width: 100,
                height: 100,
                //Sets type as Bpmn and shape as event
                shape: {
                    type: 'Basic',
                    shape: 'Rectangle',
                    cornerRadius: 0
                },
                style: {
                    fill: '#6BA5D7',
                    strokeColor: 'white'
                },
                annotations: [{
                    // Sets the text to be diaplayed
                    content: name
                }],
                method: this.method
            }
            diagramInstance.add(node);
            this.item.nodes.push(node);

            this.start += 200;
        },
        async onInput(e) {
            this.isLoading = true;

            let request = {search: e};
            axios.get(`${this.$root.serverUrl}/admin/scraper/config/handlers`, {params: request}).then((resp) => {
                if(resp.data){
                    this.handlers = resp.data.data;

                    for (const handler of resp.data.data) {
                        if (handler && this.item.handler && handler.id === this.item.handler.id) this.datapoints = handler.datapoints;
                    }
                }
            }).catch((err) => {
                console.log(err);
                swal({title: 'Oops', text: err.message, icon: 'error'})
            }).finally(() => {
                this.isLoading = false
            });
        },
        async changeHandler(e) {
            this.item.methods = e.handler;
            this.item.prerequisite = e.prerequisite;
            this.datapoints = e.datapoints;
            this.item.url = e.website;
            this.$forceUpdate();
        },
        clearNodes() {
            let diagramInstance;
            let diagramObj = document.getElementById("diagram");
            diagramInstance = diagramObj.ej2_instances[0];

            for (let i = 0; i < this.item.nodes.length; i++) {
                diagramInstance.remove(this.item.nodes[i].id);
            }

            this.item.nodes = [];

            diagramInstance.refresh();

            this.start = 50;
            this.ready = false;
            this.$nextTick(() => {
                this.ready = true;
            })
        }
    }
};
</script>

<style scoped>
@import "../../../node_modules/@syncfusion/ej2-vue-diagrams/styles/material.css";
@import "../../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";

.modal-dialog {
    height: 1000px;
    display: flex;
    max-width: 800px;
}
.modal-header {
    background: #0d0d48;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}

.modal-body {
    overflow: scroll;
}
.info-sec i {
    font-size: 100px;
}
.v-select2-container {
    width: 100%; /* Set the width to 100% */
}
.select2-container .select2-selection--single {
    height: 38px; /* Set the width to 100% */
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 38px;
}
.e-listbox {
    min-height: 400px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
