<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-search"></i> Bandwidth Inspector
        </h2>
        <div class="card">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 col-md-3">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Date</h5>
                            <div class="wrap">
                                <ejs-datepicker cssClass="e-outline" placehoder="Date" v-model="filters.date" ></ejs-datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Domain</h5>
                            <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper" style="width: 100%;">
                              <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Search" v-model="filters.domain" />
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="search">
                                <span class="e-btn-content">SEARCH</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="container-fluid">
                <div class="row">
                    <b-table :fields="columnFields" :items="items" v-if="items.length > 0 ">
                        <template v-slot:cell(proxied)="row">
                            <b class="d-flex align-content-center">{{ row.item.proxied | bandwidth }}</b>
                        </template>
                        <template v-slot:cell(cached)="row">
                            <b class="d-flex align-content-center">{{ row.item.cached | bandwidth }}</b>
                        </template>
                        <template v-slot:cell(local)="row">
                            <b class="d-flex align-content-center">{{ row.item.local | bandwidth }}</b>
                        </template>
                        <template v-slot:cell(analyse)="row">
                            <a class="btn btn-info" v-b-tooltip.hover.top title="Analyse Domain" :href="`/bandwidth/analyse?domain=${encodeURI(row.item.link)}&date=${filters.date.toLocaleDateString()}`" target="_blank"><span class="fa fa-wrench" title="Analyse"></span></a>
                        </template>
                    </b-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";

import swal from "sweetalert";

Vue.use(DatePickerPlugin);

export default {
    name: "BandwidthInspector",
    props: ["user"],
    data: function () {
        return {
            page: 0,
            max: 10,
            items: [],
            filters: {
                date: moment().toDate(),
                domain: ""
            }
        };
    },
    computed: {
        columnFields() {
            return [
                {
                    key: 'link',
                    label: 'Domain',
                    sortable: true
                },
                {
                    key: 'proxied',
                    label: 'Proxy Bandwidth',
                    sortable: true
                },
                {
                    key: 'cached',
                    label: 'Cache Bandwidth',
                    sortable: true
                },
                {
                    key: 'local',
                    label: 'Local Bandwidth',
                    sortable: true
                },
                {
                    key: 'analyse',
                    sortable: false
                },
            ]
        }
    },
    created: function () {
        this.$root.preloader = false;
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        search() {
            var request = { filters: {}, $top: this.max, $skip: this.page * this.max };
            if (this.filters.domain) request.filters.domain = this.filters.domain;
            if (this.filters.date) {
                request.filters.start = moment(this.filters.date).startOf('day').toDate();
                request.filters.end = moment(this.filters.date).endOf('day').toDate();
            }

            axios.get(`${this.$root.serverUrl}/admin/bandwidth`, {params: request}).then((resp) => {
                if(resp.data){
                    this.items = resp.data.Items || [];
                    this.count = resp.data.Count || 0;

                    this.$root.preloader = false;
                }
            }).catch((err) => {
                console.log(err);
                swal({title: 'Oops', text: err.message, icon: 'error'})
            });
        },
        windowListener: function (e) {
            if (e.keyCode === 13 && !this.processing) {
                this.search();
            }
        },
    }
};
</script>

<style>

</style>
