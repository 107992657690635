import { render, staticRenderFns } from "./Scraper.vue?vue&type=template&id=68e08d50&"
import script from "./Scraper.vue?vue&type=script&lang=js&"
export * from "./Scraper.vue?vue&type=script&lang=js&"
import style0 from "./Scraper.vue?vue&type=style&index=0&id=68e08d50&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports