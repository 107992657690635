<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Domain Bandwidth
        </h2>
        <div class="card">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-2 col-md-3">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>From</h5>
                            <div class="wrap">
                                <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.start" ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-3">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>To</h5>
                            <div class="wrap">
                                <ejs-datetimepicker cssClass="e-outline" id='startPicker' placeholder="start" v-model="filters.end" ></ejs-datetimepicker>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4">
                        <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                            <h5>Domain</h5>
                            <span class="e-input-group e-control-wrapper e-outline e-valid-input e-datetime-wrapper" style="width: 100%;">
                              <input class="e-control e-textbox e-outline e-lib e-input e-keyboard" placeholder="Search" v-model="filters.domain" />
                            </span>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-2">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">
                                <span class="e-btn-content">SEARCH</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ejs-grid ref="grid" :dataSource="data" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :query="query" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings">
            <e-columns>
                <e-column field="link" headerText="Domain" text-Align="Left" width="100"></e-column>
                <e-column field="clicks" headerText="Clicks" text-Align="Left" width="100"></e-column>
                <e-column field="proxied" :template="proxied" headerText="Bandwidth" text-Align="Left" width="100"></e-column>
                <e-column field="perclick" :template="perclick" headerText="Per Click Usage" text-Align="Left" width="100"></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DatePickerPlugin } from "@syncfusion/ej2-vue-calendars";

//import swal from "sweetalert";
import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Filter, Group,Toolbar} from '@syncfusion/ej2-vue-grids';
import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
Vue.use(DatePickerPlugin);

export default {
    name: "DomainBandwidth",
    props: ["user"],
    data: function () {
        return {
            filters: {
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                domain: ""
            },
            temp: {
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                domain: ""
            },
            queryR: {
                state: null,
                city: null
            },
            data: null,
            state: "",
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
            preloader: false,
            view: "default",
            proxied: function () {
                return {
                    template: Vue.component('proxied', {
                            template: `<div>{{ data.proxied | bandwidth }}</div>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            }
                        }
                    )
                }
            },
            perclick: function () {
                return {
                    template: Vue.component('proxied', {
                            template: `<div>{{ data.perclick | bandwidth }}</div>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            }
                        }
                    )
                }
            },
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' },
        };
    },
    computed: {
        query() {
            console.log(this.temp)
            let filters = Object.assign({}, this.temp);
            return new Query().addParams('filters', JSON.stringify(filters));
        },
    },
    created: function () {
        this.$root.preloader = false;
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        filter(){
            this.$nextTick(() => {
                if (!this.data) {
                    this.data = new DataManager({
                        url: `${this.$root.serverUrl}/admin/bandwidth`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers: [{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })
                }
                let filters = Object.assign({}, this.filters);
                filters.city = Object.assign([], this.selected_cities);
                if (this.domain) filters.domain = this.domain;
                this.temp = filters;
                console.log(filters)
            })
        },
        toolbarClick: function(args = ClickEventArgs) {

            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties: function() {
            return {
                fileName: "qso_export.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: "qso_export.pdf"
            };
        },
        windowListener: function (e) {
            if (e.keyCode === 13 && !this.processing) {
                // this.load();
            }
        },
        exportToExcel: function () {
            // this.$root.preloader = true;
            //Load the catchers
            var request = Object.assign({ apikey: this.$root.apikey }, this.form);

            this.eLoading = true;
            this.preloader = this.$root.preloader = true;

            return axios
                .get(`${this.$root.serverUrl}/static/ga/export`, {
                    params: request,
                    json: true,
                })
                .then(async (resp) => {
                    if (resp == undefined || resp.length == 0) {
                        // this.datasource = { total: 0, data: [] };
                        this.eLoading = false;
                        this.preloader = this.$root.preloader = false;
                    } else {
                        //request = this.serialize(resp);
                        //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
                        //this.eLoading = false;
                        // this.preloader = this.$root.preloader = false;
                    }
                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                });
        },
    },
    provide: {
        grid: [Page, Sort, Filter,Group,Toolbar,PdfExport,ExcelExport]
    },
};
</script>

<style>

</style>
