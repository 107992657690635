import dateFormat from 'dateformat';
import numeral from 'numeral';
import moment from "moment-timezone";
import moment_time from 'moment';
import ua_parser from 'ua-parser-js';
import {$array} from 'alga-js'

var filters = {};

filters.userTime = function (time, timezone) {
    if(timezone == undefined){ //if no timezone from user
        return time
    }
        let data = moment(time);
        return data.tz(timezone).format('YYYY-MM-DD hh:mm A');
}

// eslint-disable-next-line no-unused-vars
filters.makeID = function (text) {
    if (!text) return "";

    var id = text.toString(), ctr = 0;
    //var timestamp = parseInt(id.slice(ctr, (ctr += 8)), 16);
    //var machineID = parseInt(id.slice(ctr, (ctr += 6)), 16);
    //var processID = parseInt(id.slice(ctr, (ctr += 4)), 16);
    var counter = parseInt(id.slice(ctr, (ctr += 6)), 16);
    return counter;
}

// eslint-disable-next-line no-unused-vars
filters.makeDate = function (text) {
    if (!text) return "";

    var id = text.toString(), ctr = 0;
    var timestamp = parseInt(id.slice(ctr, (ctr += 8)), 16);
    //var machineID = parseInt(id.slice(ctr, (ctr += 6)), 16);
    //var processID = parseInt(id.slice(ctr, (ctr += 4)), 16);
    //var counter = parseInt(id.slice(ctr, (ctr += 6)), 16);
    return dateFormat(new Date(timestamp * 1000), 'yyyy-mm-dd');
}

filters.bandwidth = function (bytes) {
    if(!bytes) return '0 Bytes'
    var i = Math.floor(Math.log(bytes) / Math.log(1024)),
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return (bytes / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + sizes[i];
}

filters.useragent = function (userAgent) {
    let parser = new ua_parser();
    parser.setUA(userAgent);
    let result = parser.getResult();
    let device = (result.device.model) ? result.device.model : 'NA';
    return `${device} / ${result.browser.name} / ${result.browser.version}`;
}

filters.searchType = (data) => {
    switch (data) {
        case 'brand':
            return 'Brand Search'
        case 'photo':
            return 'Photo Search'
        case 'likeness':
            return 'Likeness Search'
        case 'video':
            return 'Video Search'
        default:
            return 'N/A'
    }
}

// eslint-disable-next-line no-unused-vars
filters.makeDateTime = function (text) {
    if (!text) return "";

    var id = text.toString(), ctr = 0;
    var timestamp = parseInt(id.slice(ctr, (ctr += 8)), 16);
    //var machineID = parseInt(id.slice(ctr, (ctr += 6)), 16);
    //var processID = parseInt(id.slice(ctr, (ctr += 4)), 16);
    //var counter = parseInt(id.slice(ctr, (ctr += 6)), 16);

    let date = new moment(new Date(timestamp * 1000));
    return date.format('YYYY[-]MM[-]DD HH[:]mm[:]ss');
}

filters.upper = function (text) {
    return text.toUpperCase();
}

filters.lower = function (text) {
    return text.toLowerCase();
}

filters.replace = function (text, target, replacement) {
    return text.replace(target, (replacement)? replacement : '').trim();
}

filters.phone_format = function (phone) {
    var phoneTest = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);

    phone = phone.trim();
    var results = phoneTest.exec(phone);
    if (results !== null && results.length > 8) {

        return "(" + results[3] + ") " + results[4] + "-" + results[5] + (typeof results[8] !== "undefined" ? " x" + results[8] : "");

    }
    else {
        return phone;
    }
}

filters.defaultValue = function (varname, value) {
    value = (value != undefined)? value : '';
    return (varname == undefined)? varname : value;
}

// eslint-disable-next-line no-unused-vars
filters.date_format = function (text, format) {
    format = (format)? format : 'yyyy-mm-dd';
    return dateFormat(text, 'yyyy-mm-dd');
}

// eslint-disable-next-line no-unused-vars
filters.date_format2 = function (text, format) {
    format = (format)? format : 'mm-dd-yyyy';
    return dateFormat(text, 'mm-dd-yyyy');
}

filters.date_format_with_time = function (date) {
    return moment_time(date, ["YYYY-MM-DDTHH:mm:ss.SSSZ"]).format('YYYY-MM-DD HH:mm')
}

filters.currency = function(value) {
    value = parseFloat(value)
    if (!isFinite(value) || (!value && value !== 0)) return '';
    return numeral(value).format("0,0"); // displaying other groupings/separators is possible, look at the docs
}

//all these filters are for the client report
filters.tablesumAvg = function(data){
    let value = $array.sum(data,'tosAvg')
    let  datetime;
    if(value != 0) {datetime = new Date(value/data.length * 1000).toISOString().substr(11, 8);
    return  datetime
    }
    return 0

}
filters.tablesumPercentage = function(data){
    let value = $array.sum(data,'successp')
     if(value != 0){ return value/data.length} return 0
}

filters.tablesumSuccess = (data) =>  $array.sum(data,'success')

filters.tablesumRequested = (data) => $array.sum(data,'attempts')

filters.toTime = (s)=> new Date(s * 1000).toISOString().substr(11, 8)

export default filters;
