<template>
    <div>
        <div id="loader" class="text-center" v-show="$root.preloader">
            <h1>Crawler</h1>
            <h4>Please wait</h4>
        </div>
        <div class="page" v-show="!$root.preloader">
            <Nav v-if="user" :user="user"></Nav>
            <div class="container-fluid">
                <router-view :class="{'pt-4': $route.path != '/chat'}" :user="user" :key="$route.path"></router-view>
            </div>

        </div>
    </div>
</template>

<script>
    import Nav from '../components/Nav.vue';
    import { mapState } from 'vuex';

    export default {
        name: 'app',
        components: {
            Nav
        },
        computed: {
          ...mapState(['user'])
        },
        data: function () {
            return {
            }
        },
        created: function(){
            if(this.$root.apikey)
                this.$root.load();
        }
    }
</script>

<style>
    body {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        background-color: #F4F4F4;
        font-size: 14px;
    }
    #loader {
        position: fixed;
        top:0;
        bottom:0;
        left:0;
        right:0;
        padding: 15% 20px;
        background-color:#fff;
    }
/* @import "../../node_modules/@syncfusion/ej2-base/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-buttons/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-lists/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-popups/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-popups/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-navigations/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-grids/styles/material.css"; */
/* @import "../../node_modules/@syncfusion/ej2-pivotview/styles/material.css"; */
</style>
