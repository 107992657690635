<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-clipboard"></i> Debug Crawler</h2>

        <div class="row">
            <div class="col-md-4 col-sm-12">
                <div class="row mb-5">
                    <div class="col-12">
                        <div class="card">
                            <!--        <b-card-body>-->
                            <div class="card-body">
                                <div class="row mb-4">
                                    <div class="col-6 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">Product</label>
                                        <select class="form-control form-control-sm" v-model="settings.product_type">
                                            <option disabled value="">Select Product</option>
                                            <option v-for="(prod, index) in products" :key="index" :value="prod.value">{{ prod.text }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-3">
                                        <label for="link_level" class="form-label float-start" style="font-weight: bold">Link Level</label>
                                        <input type="number" min="0" step="1" class="form-control form-control-sm" v-model.number="settings.linkLevel">
                                    </div>
                                    <div class="col-3">
                                        <label class="form-label float-start" style="font-weight: bold">Language</label>
                                        <select class="form-control form-control-sm" v-model="settings.lang">
                                            <option disabled value="">Select Language</option>
                                            <option v-for="(lang, index) in languages" :key="index" :value="lang.value">{{ lang.text }}</option>
                                        </select>
                                    </div>
                                    <div class="col-3">
                                        <label class="form-label float-start" style="font-weight: bold">Proxy</label>
                                        <select class="form-control form-control-sm" v-model="settings.proxy">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                    <div class="col-3">
                                        <label for="link_level" class="form-label float-start" style="font-weight: bold">Time on Site</label>
                                        <input type="number" min="0" step="1" class="form-control form-control-sm" v-model.number="settings.timeonsite">
                                    </div>
                                </div>
                                <div class="row mb-4">
                                    <div class="col-12">
                                        <div class="form-check form-switch">
                                            <input class="form-check-input" type="checkbox" v-model="advanced" role="switch" id="flexSwitchCheckChecked">
                                            <label class="form-check-label float-start" for="flexSwitchCheckChecked">Show Advanced Options</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" v-if="advanced">
                                    <hr class="w-100 m-0" />
                                    <h5 class="m-2">Advanced Information</h5>
                                    <hr class="w-100 m-0 mb-3" />
                                </div>
                                <div class="row mb-4" v-if="advanced">
                                    <div class="col-3">
                                        <label class="form-label float-start" style="font-weight: bold">Side Load</label>
                                        <select class="form-control form-control-sm" v-model="settings.sideload">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                    <div class="col-3">
                                        <label class="form-label float-start" style="font-weight: bold">Bounce</label>
                                        <select class="form-control form-control-sm" v-model="settings.bounce">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                    <div class="col-3">
                                        <label class="form-label float-start" style="font-weight: bold">Mobile</label>
                                        <select class="form-control form-control-sm" v-model="settings.mobile">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-4" v-if="advanced">
                                    <div class="col-3">
                                        <label class="form-label float-start" style="font-weight: bold">Behavior</label>
                                        <input type="text" class="form-control form-control-sm" v-model.number="settings.behaviors">
                                    </div>
                                    <div class="col-3">
                                        <label class="form-label float-start" style="font-weight: bold">Navigation Timeout</label>
                                        <input class="form-control form-control-sm" type="number" min="0" step="500" v-model="settings.timeout" />
                                    </div>
                                    <div class="col-6">
                                        <label class="form-label float-start" style="font-weight: bold">Wait Until</label>
                                        <input type="text" class="form-control form-control-sm" v-model.number="settings.waitUntil">
                                    </div>
                                </div>
                                <div class="row mb-4" v-if="advanced">
                                    <div class="col-3 mb-3">
                                        <label class="form-label float-start" style="font-weight: bold">Video</label>
                                        <select class="form-control form-control-sm" v-model="settings.video">
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </select>
                                    </div>
                                    <div class="col-3" v-if="settings.video === 'yes'">
                                        <label class="form-label float-start" style="font-weight: bold">Video FPS</label>
                                        <input class="form-control form-control-sm" type="number" min="5" max="30" step="1" v-model.number="settings.videoFps" />
                                    </div>
                                    <div class="col-3" v-if="settings.video === 'yes'">
                                        <label class="form-label float-start" style="font-weight: bold">Video Quality</label>
                                        <input class="form-control form-control-sm" type="number" min="1" max="100" step="1" v-model.number="settings.videoQuality" />
                                    </div>
                                    <div class="col-6">
                                        <label class="form-label float-start" style="font-weight: bold">Referrers</label>
                                        <input type="text" class="form-control form-control-sm" v-model.number="settings.referersArray">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 v-if="settings.proxy == 'yes'" class="mt-4 pl-3" style="text-align: left;">Proxy</h5>
                    <div v-if="settings.proxy == 'yes'" class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row mb-4">
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">Provider</label>
                                        <select class="form-control form-control-sm" v-model="proxy.provider">
                                            <option disabled value="">Select Provider</option>
                                            <option v-for="(prod, index) in providers" :key="index">{{ prod.text }}</option>
                                        </select>
                                    </div>
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">Type</label>
                                        <select class="form-control form-control-sm" v-model="proxyType">
                                            <option disabled value="">Select Type</option>
                                            <option v-for="(prod, index) in proxyTypes" :key="index">{{ prod.text }}</option>
                                        </select>
                                    </div>
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">Country</label>
                                        <select class="form-control form-control-sm" v-model="proxy.country" @change="loadstates">
                                            <option disabled value="">Select Country</option>
                                            <option v-for="(prod, index) in countries" :key="index" :value="prod.code">{{ prod.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-4" v-if="proxyType === 'Residential'">
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">State</label>
                                        <select class="form-control form-control-sm" v-model="proxy.state" @change="loadcities">
                                            <option disabled value="">Select State</option>
                                            <option v-for="(prod, index) in states" :key="index" :value="prod">{{ prod }}</option>
                                        </select>
                                    </div>
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">City</label>
                                        <select class="form-control form-control-sm" v-model="proxy.city">
                                            <option disabled value="">Select City</option>
                                            <option v-for="(prod, index) in cities" :key="index" :value="prod.city_name">{{ prod.city_name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <h5 v-if="settings.sideload == 'yes'" class="mt-4 pl-3" style="text-align: left;">Side Load Proxy</h5>
                    <div v-if="settings.sideload == 'yes'" class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row mb-4">
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">Provider</label>
                                        <select class="form-control form-control-sm" v-model="sideload.provider">
                                            <option disabled value="">Select Provider</option>
                                            <option v-for="(prod, index) in providers" :key="index">{{ prod.text }}</option>
                                        </select>
                                    </div>
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">Type</label>
                                        <select class="form-control form-control-sm" v-model="sideloadProxyType">
                                            <option disabled value="">Select Type</option>
                                            <option v-for="(prod, index) in proxyTypes" :key="index">{{ prod.text }}</option>
                                        </select>
                                    </div>
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">Country</label>
                                        <select class="form-control form-control-sm" v-model="sideload.country" @change="loadstates">
                                            <option disabled value="">Select Country</option>
                                            <option v-for="(prod, index) in countries" :key="index" :value="prod.code">{{ prod.name }}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="row mb-4" v-if="proxyType === 'Residential'">
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">State</label>
                                        <select class="form-control form-control-sm" v-model="sideload.state" @change="loadcities">
                                            <option disabled value="">Select State</option>
                                            <option v-for="(prod, index) in states" :key="index" :value="prod">{{ prod }}</option>
                                        </select>
                                    </div>
                                    <div class="col-4 col-md-4">
                                        <label class="form-label float-start" style="font-weight: bold">City</label>
                                        <select class="form-control form-control-sm" v-model="sideload.city">
                                            <option disabled value="">Select City</option>
                                            <option v-for="(prod, index) in cities" :key="index" :value="prod.city_name">{{ prod.city_name }}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-9">
                        <input class="form-control" placeholder="https://seoranker.ai/" v-model="settings.domain">
                    </div>
                    <div class="col-3">
                        <button class="btn btn-md btn-primary" @click="debug">Debug</button>
                    </div>
                </div>

            </div>

            <div class="col-md-8 col-sm-12">
                <canvas id="screencast"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import puppeteer from 'puppeteer-web';
import axios from "axios";
import swal from "sweetalert";
import cuid from "cuid";

export default {
    name: 'Debugger',
    props:['user'],
    components: {
    },
    data: function () {
        return {
            ctx: null,
            level: 1,
            canvas: null,
            page: null,
            client: null,
            browser: null,
            recorder: null,
            connected: false,
            recordingFinish: null,
            chunks: [],
            debugger: null,
            states: [],
            countries: [],
            cities: [],
            proxy: {},
            sideload: {},
            settings: {
                _id: "619e216bb9dce7d44c79bc68",
                proxy: "no",
                lang: {code: 'en', codes: 'en-US,en'},
                product_type: "adverifier",
                timeonsite: 2,
                linkLevel: 2,
                behaviors: "2000,3000,15000",
                mobile: "no",
                bounce: "no",
                geo: null,
                sideload: "no",
                timeout: 60000,
                showMouse: true,
                waitUntil: "load,domcontentloaded,networkidle2",
                video: "no",
                domain: "https://seoranker.ai/"
            },
            advanced: false,
            proxyType: "nation wide",
            sideloadProxyType: "nation wide",
            clients: [
                { text: 'ADR', value: 'adretreaver' },
            ],
            providers: [
                { text: 'Bright Data', value: 'brightdata' },
                { text: 'Oxylabs', value: 'oxylabs' }
            ],
            proxyTypes: [
                { text: 'Nation Wide', value: 'nationwide' },
                { text: 'Residential', value: 'residential' },
            ],
            products: [
                { text: 'Email Verifier', value: 'emailverifier' },
                { text: 'AD Verifier', value: 'adverifier' },
            ],
            languages: [
                { text: 'English', value: {code: 'en', codes: 'en-US,en'} },
                { text: 'Spanish', value: {code: 'es', codes: 'es-US,es'} },
            ]
        }
    },
    created: function(){
        window.addEventListener("keydown",this.windowListener);
    },
    destroyed: function() {
        window.removeEventListener("keydown",this.windowListener);
    },
    mounted() {
        this.$root.preloader = false;
        this.canvas = document.querySelector('#screencast');
        this.ctx = this.canvas.getContext('2d');
        this.loadcountries();
    },
    methods:{
        async awaitConnect() {
            while (!this.connected && this.debugger) {
                try {
                    await this.connect();
                } catch (err) {
                    console.log(err)
                }
                if (this.connected) return;
                await new Promise(resolve => setTimeout(resolve, 2000));
            }
        },
        loadcountries: function () {
            var request = { sSearch: "" };

            return axios
                .get(`${this.$root.serverUrl}/admin/countries`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.countries = resp.data && resp.data.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        loadstates: function () {
            this.states = [];
            var request = { filters: {} };

            if (this.settings.proxy.country)
                request.filters.country_iso_code = this.settings.proxy.country;

            return axios
                .get(`${this.$root.serverUrl}/admin/geos/state`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.states = resp.data && resp.data.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        loadcities: function () {
            this.cities = [];
            var request = { filters: {} };

            if (this.settings.proxy.country)
                request.filters.country_iso_code = this.settings.proxy.country;
            if (this.settings.proxy.state)
                request.filters.subdivision_1_iso_code = this.settings.proxy.state;

            return axios
                .get(`${this.$root.serverUrl}/admin/geos/city`, { params: request })
                .then(
                    function (resp) {
                        //Store the stats
                        this.cities = resp.data ? resp.data.data : [];
                    }.bind(this)
                );
        },
        async debug() {
            let request = this.settings;
            if(request.proxy === "yes") request.proxies = [this.proxy];
            if(request.sideload === "yes") request.proxies = [this.sideload];
            request.debugger = cuid();

            axios.post(`http://localhost:3040/job/97c436769131ec666092f72093a0e45ee9323261d6645dc42b3a785a3ec052db`, request).then((resp) => {
                console.log(resp)
                //Process the results
                if (resp.data && !resp.data.error) {
                    console.log("no error", request.debugger)
                    this.debugger = request.debugger;
                    this.awaitConnect();
                }

                if (resp.data.error) {
                    //Handle errors
                    this.errormsg = resp.data.error ? resp.data.error.message : false;
                    swal({ title: "Error", text: this.errormsg, icon: "error" });
                }
            })
            .catch(
                function (err) {
                    this.errormsg = err.message || "An unexpected error occured";
                    swal({ title: "Error", text: this.errormsg, icon: "error" });
                }.bind(this)
            )
        },
        async connect() {
            let resp = await axios.get(`http://localhost:3040/jobs`);
            console.log(resp.data)

            let jobs = resp.data && resp.data.endpoints ? resp.data.endpoints : [];
            let filtered = jobs.filter((job) => job.debugger == this.debugger);

            if (!filtered.length) return;

            this.browser = await puppeteer.connect({
                browserWSEndpoint: filtered[0].endpoint,
                ignoreHTTPSErrors: true
            }).catch((error) => {
                console.error(error);
                throw error;
            });

            this.connected = true;

            let pages = await this.browser.pages();
            for (let i = 0; i < pages.length; i++) {
                this.page = pages[i]
            }

            this.client = (this.page)._client;

            this.viewport = await this.page.viewport();

            this.ctx.canvas.width = this.viewport.width;
            this.ctx.canvas.height = this.viewport.height;

            await this.client.send('Page.startScreencast', {
                format: 'jpeg',
                maxWidth: this.viewport.width,
                maxHeight: this.viewport.height,
            });

            // Setup the screencast event
            this.client.on('Page.screencastFrame', ({ data, sessionId }) => {
                this.draw(data);
                this.client.send('Page.screencastFrameAck', { sessionId }).catch((err) => {
                    console.log(err)
                });
            });

            this.browser.once('disconnected', () => {
                console.log("Browser Disconnected");
                this.level++;
                this.connected = false;
                if (this.level < this.settings.linkLevel + 1) this.awaitConnect();
            });
        },
        async beginRecording(stream) {
            return new Promise((resolve, reject) => {
                // @ts-ignore No MediaRecorder
                this.recorder = new MediaRecorder(stream, { mimeType: 'video/webm' });
                this.recorder.ondataavailable = (e) => this.chunks.push(e.data);
                this.recorder.onerror = reject;
                this.recorder.onstop = resolve;
                this.recorder.start();
            });
        },
        async start({ width, height }) {
            this.canvas.width = width;
            this.canvas.height = height;
            // @ts-ignore No captureStream API
            this.recordingFinish = this.beginRecording(this.canvas.captureStream());
        },

        async draw(pngData) {
            const data = await fetch(`data:image/png;base64,${pngData}`)
                .then(res => res.blob())
                .then(blob => createImageBitmap(blob));

            this.ctx.clearRect(0, 0, this.viewport.width, this.viewport.height);
            this.ctx.drawImage(data, 0, 0);

            return this;
        },
        stop() {
            this.recorder.stop();
            return this;
        }
    }
}
</script>

<style scoped>

</style>
