<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Chat GPT Sessions
            <span style="float: right;display: flex;gap: 10px;">
                <span>
                    <select
                        v-model="model"
                        class="form-control"
                    >
                        <option
                            v-for="model in models"
                            :key="model.description"
                            :value="model"
                        >
                            {{ model.description }}
                        </option>
                    </select>
                </span>
                <button :disabled="!model" class="btn btn-sm btn-info" @click="$router.push(`/chat?key=${model.key}`)">
                    <i class="fa fa-comment" ></i> New Session
                </button>
            </span>
        </h2>
        <div class="container-fluid">
            <ejs-grid ref="grid" :dataSource="data" :query="query" :allowPaging="true" :allowSorting="true" :pageSettings="pageSettings">
                <e-columns>
                    <e-column field="date" headerText="Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'medium'}"></e-column>
                    <e-column field="user.name" headerText="User" text-Align="Left" ></e-column>
                    <e-column field="model" headerText="Model" text-Align="Left"></e-column>
                    <e-column field="session" headerText="Session" text-Align="Left"></e-column>
                    <e-column field="question" headerText="Question" text-Align="Left"></e-column>
                    <e-column :template="actionTemplate"></e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</template>

<script>

import {GridPlugin, Page, Sort} from '@syncfusion/ej2-vue-grids';
import Vue from "vue";
import {DataManager, Query, WebApiAdaptor} from "@syncfusion/ej2-data";
import axios from "axios";
import swal from "sweetalert";
Vue.use(GridPlugin);

export default {
    name: "CHATGPTS",
    provide: {
        grid: [Page, Sort]
    },
    props: ["user"],
    data: function () {
        return {
            model: null,
            models: [],
            chats: [],
            pageSettings: { pageSize: 50, pageSizes: [50, 100, 500] },
            data: new DataManager({
                url: `${this.$root.serverUrl}/admin/chat`,
                adaptor: new WebApiAdaptor(),
                crossDomain: true,
                headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
            }),
            actionTemplate: function () {
                return {
                    template: Vue.component('tosTemplate', {
                            template: `<a :href="'/chat?session=' + data.session" target="_blank">
                                        <button class='btn btn-primary btn-sm'>
                                          <span class="fa fa-eye" title="View"></span>
                                        </button>
                                        </a>`,
                            data: function () {
                                return {
                                    data: {}
                                }
                            }
                        }
                    )
                }
            }
        };
    },
    computed: {
        query() {
            let filters = Object.assign({}, this.temp);
            return new Query().addParams('filters', JSON.stringify(filters));
        }
    },
    components: { },
    created: function () {
        this.loadKeys();
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        filter() {
            let filters = Object.assign({}, this.filters);
            this.temp = filters;
        },
        loadChat(chat) {
          this.$router.push(`/chat?session=${chat.session}`)
        },
        loadKeys: function(){
            return axios.get(`${this.$root.serverUrl}/admin/chat/list/keys`).then((resp) => {
                this.models = (resp.data && resp.data.Items)? resp.data.Items : [];

                if (resp && resp.data && resp.data.error) {
                    swal({ title: "Error", text: "There was an error loading keys.", icon: "error" });
                }
                this.$root.preloader = false;
            }).catch((err) => {
                console.log(err)
                swal({ title: "Error", text: "There was an error loading keys.", icon: "error" });
            });
        }
    }
};
</script>
