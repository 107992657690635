<template>
    <!-- The Modal -->
    <div class="modal fade" id="viewModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">

                <!-- Modal Header -->
                <div class="modal-header">
                    <h4 class="modal-title">{{item ? item.name : null}} Report</h4>
                    <button type="button" class="close" data-dismiss="modal" @click="$emit('close')">&times;</button>
                </div>

                <!-- Modal body -->
                <div class="modal-body" style="position: relative;">

                    <div class="dialog" v-if="dialog">
                        <div class="dialog-content">
                            <button @click="toggleDialog()" class="close-icon" >close x</button>
                            <img :src="imageSrc">
                        </div>
                    </div>

                    <div class="mb-4" v-for="(item, index) in data" :key="index">
                        <div><strong>{{ item.column.charAt(0).toUpperCase() + item.column.slice(1) }}</strong></div>
                        <div v-if="!item.value.type">{{ item.value }}</div>
                        <div v-if="item.value.type == 'text'">{{ item.value.value }}</div>
                        <div v-if="item.value.type == 'image'">
                            <div class="col-lg-12 control-section card-control-section vertical_card_layout">
                                <div class="e-card-resize-container">
                                    <div class="row">
                                        <div class="col-xs-6 col-sm-6 col-lg-6 col-md-6" @click="toggleDialog(item.value.value)">
                                            <div tabindex="0" class="e-card" id="vertical_business_profile" :style="'background: url(' + item.value.value + ')'">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="config && config.chatgpt && config.chatgpt === 'yes'">
                        <div>
                            Rate this answer:
                            <div class="row">
                                <div class="col-md-4">
                                    <input v-model="rate" type="number" min="0" max="100" class="form-control">
                                </div>
                                <div class="col-md-4">
                                    <button @click="rateAnswer" class="btn btn-info">Rate</button>
                                </div>
                            </div>

                        </div>
                        <div  class="mt-5 mb-4" v-for="(rating, index) in votes" :key="index">
                            <div><strong>{{ rating.name }}</strong>  {{ rating.vote }}</div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import swal from "sweetalert";

export default {
    name: "ViewModal",
    props: [
        "item",
        "id",
        "votes",
        "config"
    ],
    components: { },
    data() {
        return {
            rate: "",
            imageSrc: "",
            dialog: false
        }
    },
    mounted() {

    },
    computed: {
        data() {
            let data = []
            for (const key in this.item) {
                data.push({ column: key, value:  this.item[key]})
            }
            return data
        }
    },
    methods: {
        toggleDialog(screenshot){
            this.imageSrc = screenshot
            this.dialog = !this.dialog;
        },
        rateAnswer() {
            var request = {apikey: this.$root.apikey, id: this.id, vote: this.rate};

            axios({
                url: `${this.$root.serverUrl}/admin/scraper/config/result/rate`,
                data: request,
                responseType: "json",
                method: "PUT",
            })
                .then((resp) => {
                    //Process the results
                    this.$root.preloader = false;
                    if (resp.data && !resp.data.error) {
                        if (!this.votes) this.votes = []
                        this.votes.push(resp.data.data)
                    } else {
                        //Handle errors
                        swal({
                            title: "Like",
                            text: resp.data.error.message,
                            icon: "error",
                        });
                    }
                })
                .catch((err) => {
                    this.$root.preloader = false;
                    this.errormsg = err.message || "An unexpected error occurred";
                    swal({title: "Error", text: this.errormsg, icon: "error"});
                });
        },
        getRating() {
            let request = {filters: {id: this.item.id}};
            axios.get(`${this.$root.serverUrl}/admin/scraper/config/results/ratings`, {params: request}).then((resp) => {
                this.ratings = resp.data.data;

                this.$root.preloader = false;
            }).catch((err) => {
                swal({title: "Error", text: err, icon: "error"});
            });
        }
    }
};
</script>

<style scoped>
.modal-dialog {
    max-width: 90%;
    height: 90vh;
    display: flex;
    max-width: 1620px;
}
.modal-header {
    background: #0d0d48;
}
.modal-body {
    overflow: scroll;
}
.modal-title {
    color: #FFFFFF;
}
.close {
    color: #FFFFFF;
}

.dialog {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    background: rgba(0,0,0,0.5);
    border: 1px solid #999;
    overflow-y: scroll;
    z-index: 99999;
}
.dialog-content {
    width: auto;
    height: auto;
    margin: 0 auto;
    padding: 10px;
    margin-top: 20px;
}
.dialog-content img {
    width: 100%;
    height: auto;
}
.close-icon {
    float: right;
}

.card-control-section.vertical_card_layout #vertical_business.e-card .e-card-header .e-card-header-title,
.card-control-section.vertical_card_layout #vertical_business_profile.e-card .e-card-header .e-card-header-title {
    font-size: 18px;
}

.card-control-section.vertical_card_layout #vertical_business_profile.e-card .e-card-header {
    text-align: left;
}

.card-control-section.vertical_card_layout #vertical_business_profile.e-card>* {
    height: 50%;
}

.card-control-section.vertical_card_layout #vertical_business_profile.e-card .e-card-content {
    display: flex;
    flex-direction: column;
}

.card-control-section.vertical_card_layout #vertical_business_profile.e-card .e-card-header-caption .e-card-sub-title,
.card-control-section.vertical_card_layout #vertical_business_profile.e-card .e-card-header-caption .e-card-header-title,
.card-control-section.vertical_card_layout #vertical_business_profile.e-card .e-card-content table td {
    color: #fff;
}

.card-control-section.vertical_card_layout #vertical_business.e-card {
    justify-content: flex-end;
}

.card-control-section.vertical_card_layout #vertical_business.e-card,
.card-control-section.vertical_card_layout #vertical_business_profile.e-card {
    text-align: center;
    background-size: cover;
    height: 400px;
    box-shadow: 6px 0px 30px #c9c9c9;
}

.card-control-section.vertical_card_layout #vertical_business.e-card .e-card-header-caption .e-card-header-title,
.card-control-section.vertical_card_layout #vertical_business.e-card .e-card-header-caption .e-card-sub-title,
.card-control-section.vertical_card_layout #vertical_business.e-card .e-card-actions button {
    color: #000;
}

.card-control-section.vertical_card_layout #vertical_business.e-card {
    background-image: url('../../assets/logo.png');
}

.card-control-section.vertical_card_layout #vertical_business_profile.e-card {
    background-image: url('../../assets/logo.png');
}

.card-control-section.vertical_card_layout #vertical_business.e-card .e-card-actions,
.card-control-section.vertical_card_layout #vertical_business_profile.e-card .e-card-actions {
    justify-content: center;
    padding-bottom: 40px;
    padding-top: 22px;
}

.card-control-section.vertical_card_layout #vertical_business.e-card .e-card-actions button,
.card-control-section.vertical_card_layout #vertical_business_profile.e-card .e-card-actions button {
    background-color: transparent;
    text-transform: none;
    border: none;
}

.highcontrast .card-control-section.vertical_card_layout .e-card.profile .e-card-header-image {
    border-color: #fff;
}

.card-control-section.vertical_card_layout .e-card.profile .e-card-header-image {
    background-image: url('../../assets/logo.png');
    width: 132px;
    height: 120px;
    background-size: cover;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.54);
}

.card-control-section.vertical_card_layout .e-card.profile .e-card-header-caption.center,
.card-control-section.vertical_card_layout .e-card.profile .e-card-content {
    text-align: center;
}

.card-control-section.vertical_card_layout .e-card.profile .e-card-btn {
    height: 45px;
    width: 50px;
    background-color: transparent !important;
    border: none;
}

.card-control-section.vertical_card_layout .e-card.profile .e-card-actions button.e-card-btn:hover,
.card-control-section.vertical_card_layout .e-card.profile .e-card-actions a:first-child:hover,
.card-control-section.vertical_card_layout .e-card.profile .e-card-actions button.e-card-btn,
.card-control-section.vertical_card_layout .e-card.profile .e-card-actions a:first-child {
    background: #ffff
}

.card-control-section.vertical_card_layout .e-card .e-card-actions.center {
    justify-content: center;
    display: flex;
}

.card-control-section.vertical_card_layout .e-card.profile .e-card-actions.center {
    background-color: transparent;
    border: none;
}

.card-control-section.vertical_card_layout .e-card.profile .e-card-header .e-card-header-caption .e-card-sub-title {
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.8);
}

.card-control-section.vertical_card_layout .e-card.profile .e-card-header .e-card-header-caption .e-card-header-title {
    font-size: 18px;
    font-weight: 500;
}

.card-control-section.vertical_card_layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
    width: 100%;
    padding: 10px;
}

.card-control-section.vertical_card_layout .card-layout {
    margin: auto;
    max-width: 400px;
}

@media (min-width: 870px) {
    .card-control-section.vertical_card_layout .col-xs-6.col-sm-6.col-lg-6.col-md-6 {
        width: 50%;
    }

    .card-control-section.vertical_card_layout .card-layout {
        max-width: 870px;
    }
}
</style>
