<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Internal Billing
        </h2>
        <div class="mt-4 bg-white p-4">
            <div class="mt-4 mb-5">
                <div class="mb-4">
                    <div class="row">
                        <div class="col-lg-2 col-md-3">
                            <h6>Start Date-Time</h6>
                            <div class="wrap">
                                <ejs-datepicker :strictMode="true" :placehoder="'Select Start Date and Time'" v-model="filters.start_date" ></ejs-datepicker>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <h6>Stop Date-Time</h6>
                            <div class="wrap">
                                <ejs-datepicker :strictMode="true" :min="filters.start_date" :placehoder="'Select Stop Date and Time'" v-model="filters.stop_date" ></ejs-datepicker>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-2">
                            <div class="tabs-wrap" style="padding-top: 34px;">
                                <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" @click="search" style="color: white; background-color: rgb(56, 56, 56);" >
                                    <span class="e-btn-content">SEARCH</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ejs-grid ref="grid" :dataSource="billingsData" :toolbar='toolbar' :toolbarClick='toolbarClick' :allowExcelExport="true">
                <e-columns>
                    <e-column field='total' minWidth='8' type="number" format="N" width='50' :customAttribute="{class: 'total'}" headerText="Total"></e-column>
                    <e-column field='shown' minWidth='8' width='50' type="number" format="N" headerText="Shown"></e-column>
                    <e-column field='shownp' minWidth='8' width='50' type="number" format="N" headerText="Shown %"></e-column>
                    <e-column field='capped' headerText='Capped' type="number" format="N" minWidth='8' width='50'></e-column>
                    <e-column field='cappedp' headerText='Capped %' type="number" format="N" minWidth='8' width='50'></e-column>
                    <e-column field='matched' headerText='Matched' type="number" format="N" minWidth='8' width='50'></e-column>
                    <e-column field='matchedp' headerText='Matched %' type="number" format="N" minWidth='8' width='50'></e-column>
                </e-columns>
            </ejs-grid>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { DatePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { GridPlugin, ExcelExport, Toolbar } from '@syncfusion/ej2-vue-grids';
import axios from "axios";
import Swal from 'sweetalert2';
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
Vue.use(DatePickerPlugin);

export default {
    name: "InternalBilling",

    data() {
        return {
            filters: {},
            billingsData: [],
            toolbar: ['ExcelExport'],
        }
    },
    created() {
        this.$root.preloader = false;
    },

    provide: {
        grid: [ ExcelExport, Toolbar ],
    },
    methods: {
        search() {
            if (this.filters.start_date && this.filters.stop_date) {
                this.$refs.grid.showSpinner();
                axios.post(`${this.$root.serverUrl}/admin/reports/billing`, { filters: this.filters }).then((resp) => {
                    let raw = resp.data.data

                    let object = {
                        total: 0,
                        shown: 0,
                        capped: 0,
                        matched: 0
                    }

                    if (raw.length > 0) {
                        raw.reduce((acc, obj) => {
                            object.total = object.total + obj.total
                            object.shown = object.shown + obj.shown
                            object.capped = object.capped + obj.capped
                            object.matched = object.matched + obj.total
                        })
                        object.shownp = ((object.shown / object.total) * 100).toFixed(2)
                        object.cappedp = ((object.capped / object.total) * 100).toFixed(2)
                        object.matchedp = ((object.matched / object.total) * 100).toFixed(2)
                        this.billingsData.push(object)
                    }
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please select Start and Stop date!',
                })
            }
        },
        toolbarClick(args = ClickEventArgs) {
            this.$refs.grid.showSpinner();
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    if (this.filters.start_date && this.filters.stop_date) {
                        (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please select Start and Stop date!',
                        })
                    }
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
            this.$refs.grid.hideSpinner();
        },
        getExcelExportProperties() {
            return {
                fileName: `billing_export_${this.filters.start_date}_${this.filters.stop_date}.xlsx`
            };
        },
        getPdfExportProperties() {
            return {
                fileName: `billing_export_${this.filters.start_date}_${this.filters.stop_date}.pdf`,
            };
        },
    }
}
</script>

<style scoped>
    .total {
        background-color: darkcyan;
    }
</style>
