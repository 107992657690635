<template>
    <div>
        <div class="">
            <header class="page-header">
                <div class="container ">
                    <h2>Chat</h2>
                </div>
            </header>
            <div class="main">
                <div class="container ">
                    <div class="chat-log">
                        <template v-for="(chat, index) in chat.messages">
                            <div :class="{'pt-chat-log__item chat-log__item--own': chat.type == 'question'}" class="chat-log__item" :key="index">
                                <h3 class="chat-log__author">{{ chat.author }} <small>{{ chat.time | date_format_with_time }}</small></h3>
                                <div class="chat-log__message">{{ chat.message }}</div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="chat-form">
                    <div class="container ">
                        <form class="form-horizontal">
                            <div class="row">
                                <div class="col-sm-10 col-xs-8">
                                    <input type="text" class="form-control" id="" placeholder="Question" v-model="question" />
                                </div>
                                <div class="col-sm-2 col-xs-4">
                                    <button @click="ask" type="button" class="btn btn-success btn-block">Send</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import cuid from "cuid";
import axios from "axios";
import swal from "sweetalert";

export default {
    name: "CHATGPT",
    props: ["user"],
    data: function () {
        return {
            key: null,
            chat: { messages: []},
            question: ""
        };
    },
    computed: {},
    created: function () {
        if (!this.$route.query.session) {
            this.session = cuid();
            const query = { ...this.$route.query, session: this.session };
            this.$router.replace({ query });
            this.$root.preloader = false;
        } else {
            this.session = this.$route.query.session;
            this.load()
        }

        if (this.$route.query.key) {
            this.key = this.$route.query.key;
            let query = { ...this.$route.query};
            delete query.key;
            this.$router.replace({ query });
        }
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        load: function(){
            axios.get(`${this.$root.serverUrl}/admin/chat/${this.session}`).then((resp) => {
                //Store the stats
                this.chat = (resp.data && resp.data.data)? resp.data.data : [];
                this.$root.preloader = false;
            }).catch((err) => {
                console.log(err)
                swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
            });
        },
        ask() {
            if (!this.key) return swal({ title: 'Error', text: "This session is no longer active", icon: 'error' });

            this.chat.messages.push({time: new Date(), message: this.question, type: "question", author: this.user.name})
            axios.post(`${this.$root.serverUrl}/admin/chat/ask`, { question: this.question, session: this.session, key: this.key}).then((resp) => {
                if ( resp.data && resp.data.error ) {
                    swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
                } else {
                    this.chat.messages.push(resp.data.data)
                }
            }).catch((err) => {
                console.log(err)
                swal({ title: 'Error', text: "Unable to process your request", icon: 'error' })
            })
        }
    }
};
</script>

<style scoped>
.page-header {
    background: #006A4E;
    margin: 0;
    padding: 20px 0 10px;
    color: #FFFFFF;
    position: fixed;
    width: 100%;
    z-index: 1
}
.main {
    height: 100vh;
    padding-top: 70px;
}

.chat-log {
    padding: 40px 0 114px;
    height: auto;
    overflow: auto;
}
.chat-log__item {
    background: #fafafa;
    padding: 10px;
    margin: 0 auto 20px;
    max-width: 80%;
    float: left;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0,0,0,.1);
    clear: both;
}

.chat-log__item.chat-log__item--own {
    float: right;
    background: #DCF8C6;
    text-align: right;
}

.chat-form {
    background: #DDDDDD;
    padding: 40px 0;
    position: fixed;
    bottom: 0;
    width: 100%;
}

.chat-log__author {
    margin: 0 auto .5em;
    font-size: 14px;
    font-weight: bold;
}
</style>
