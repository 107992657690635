<template>
    <div>
        <a @click="viewDetails(data)" style="cursor: pointer; color: blue">{{ data.clicks }}</a>
        <b-modal
            v-model="modalShow"
            size="xl"
            scrollable
            ok-only ok-variant="secondary"
            ok-title="Close"
            :title="'Clicks Delivered'"
            @show="onModalShow"
        >
            <div class="row">
            <div class="col-lg-6 col-md-6 col-sm-6 mb-3 ">

                <div class="control-styles">
                    <h6>Page Size</h6>
                    <ejs-dropdownlist  cssClass="e-outline" id='sample-list3' :dataSource='pageSizes' :mode='true' v-model="pageSettings.pageSize" placeholder='Page Size' ></ejs-dropdownlist>
                </div>

            </div>
                <div class="col-lg-6 col-md-6 col-sm-6 mb-3">
                    <div class="control-styles" >
                        <h6>Columns To Be Shown</h6>
                        <!--                                <span class=" e-control-wrapper e-outline e-valid-input e-datetime-wrapper" >-->
                        <ejs-multiselect
                            :dataSource="columnOptions"
                            :fields="dfields"
                            v-model="selectedColumns"
                            @change="updateColumnVisibility"
                            placeholder="Select columns to display"
                            cssClass="e-outline"
                        ></ejs-multiselect>
                        <!--                                </span>-->
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pt-3">
                    <ejs-grid
                        ref="gridItem"
                        :dataSource="reports"
                        :toolbar='toolbar' :toolbarClick='toolbarClick' :excelExportComplete='exportComplete'
                        :pdfExportComplete='exportComplete' :allowFiltering="true" :allowExcelExport="true"
                        :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings"
                        :actionBegin="actionBegin"  :loadingIndicator='loadingIndicator'
                        :actionComplete="onGridActionComplete" :allowGrouping="true" :groupSettings='groupSettings'
                        :filterSettings="filterSettings" :csvExportComplete='exportComplete'

                    >
                        <e-columns>
                            <e-column field="date" headerText="Date" text-Align="Left"  width="80"
                                      :visible="visibleColumns.includes('date')" ></e-column>
                            <e-column field="country" headerText="Country" text-Align="Left" width="80"
                                      :visible="visibleColumns.includes('country')" ></e-column>
                            <e-column field="state" headerText="State" text-Align="Left" width="80"
                                      :visible="visibleColumns.includes('state')" ></e-column>
                            <e-column field="city" headerText="City" text-Align="Left" width="80"
                                      :visible="visibleColumns.includes('city')" ></e-column>
                            <e-column field="ip" headerText="Proxy Ip" text-Align="Left" width="80"
                                      :visible="visibleColumns.includes('ip')" ></e-column>
                            <e-column field="clicks" headerText="Clicks Delivered" text-Align="Left" width="80"
                                      :visible="visibleColumns.includes('clicks')" ></e-column>
                            <e-column field="provider" headerText="Proxy Provider" text-Align="Left"  width="80"
                                      :visible="visibleColumns.includes('provider')" ></e-column>
                            <e-column field="error" headerText="Proxy Error" text-Align="Left" width="80"
                                      :visible="visibleColumns.includes('error')" ></e-column>
                        </e-columns>

                        <e-aggregates>
                            <e-aggregate>
                                <e-columns>
                                    <e-column type="Sum" field="clicks" format="N" :footerTemplate='footerSum'></e-column>

                                </e-columns>
                            </e-aggregate>

                            <e-aggregate>
                                <e-columns>
                                    <e-column type="Custom" field="error" :customAggregate ='generateCount' :footerTemplate='footerCount' ></e-column>
                                </e-columns>
                            </e-aggregate>
                        </e-aggregates>
                    </ejs-grid>
                    <ejs-pager :pageSize='pageSettings.pageSize' :pageCount='10'
                               :totalRecordsCount='pageSettings.totalRecordsCount'
                               @click="onPageClick"
                    >
                    </ejs-pager>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';

/*import VSelect from 'vue-select'*/
import { GridPlugin, Page, PdfExport, ExcelExport, Sort,Filter, Group,Toolbar,PagerPlugin,Aggregate} from '@syncfusion/ej2-vue-grids';
// import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
import moment from "moment-timezone";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";
import axios from "axios";

Vue.use(GridPlugin);
Vue.use(PagerPlugin);
// Vue.use(TabPlugin);
export default {
    name: "clicksTemplate",
    provide: {
        grid: [Page, Sort,Group,Toolbar,PdfExport,ExcelExport,Filter, Group,Toolbar,Aggregate]
    },
    data: function() {
        return {
            columnOptions: [
                { id: "date", name: "Date" },
                { id: "country", name: "Country" },
                { id: "state", name: "State" },
                { id: "city", name: "City" },
                { id: "ip", name: "Proxy Ip" },
                { id: "clicks", name: "Clicks Delivered" },
                { id: "provider", name: "Proxy Provider" },
                { id: "error", name: "Proxy Error" },
                // Add more options for other columns
            ],
            selectedColumns:["date","country",'state','city','ip','clicks','provider','error'], // Set the initial selected columns
            visibleColumns: ["date","country",'state','city','ip','clicks','provider','error'],
            data:{},
            groupSettings:  {
                showDropArea: true,
                disablePageWiseAggregates: true,
            },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            loadingIndicator: { indicatorType: 'Shimmer' },
            pageSizes:[25,50,75],
            reports: null,
            CampaignsCount:0,
            modalTitle:"Modal Title",
            modalShow:false,
            filters: {
                view: "City",
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                domain: ""
            },
            toolbar: ['CsvExport','ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 25, pageSizes: [25,50, 100, 500], pageCount: 10},
            temp: {
                start: moment().startOf("day").toDate(),
                end: moment().endOf("day").toDate(),
                domain: ""
            },
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Total: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            footerCount: function () {
                return  { template : Vue.component('countTemplate', {
                        template: `<span>Count: {{data.Custom}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
        }
    },
    // mounted() {
    //     this.loading();
    // },
    // created() {
    //     this.loading();
    // },
    computed: {
        dfields() {
            return { text: "name", value: "id" };
        },
        // query() {
        //     let filters = Object.assign({}, this.temp);
        //     return new Query().addParams('filters', JSON.stringify(filters));
        // },
    },
    methods:{
        customAggregator(data) {
            const aggregatedCounts = {};
            data.result.forEach(item => {
                if (item.hasOwnProperty('error')) {
                    const errorId = item.error;
                    if (this.errorIdCounts.hasOwnProperty(errorId)) {
                        aggregatedCounts[errorId] = this.errorIdCounts[errorId];
                    }
                }
            });
            return JSON.stringify(aggregatedCounts);
        },
        generateCount:function (data){
            const errorIdCounts = {};
            data.result.forEach(entry => {
                const { error } = entry;
                    if (error !== null) {
                        if (errorIdCounts.hasOwnProperty(error)) {
                            errorIdCounts[error]++;
                        } else {
                            errorIdCounts[error] = 1;
                        }
                    }
            });
            const formattedString = Object.entries(errorIdCounts)
                .map(([errorId, count]) => `${errorId}:${count}`)
                .join(', ');
            return formattedString
        },
        updateColumnVisibility() {
            this.visibleColumns = this.selectedColumns;
        },
        onModalShow() {
            // Modal is shown, you can access the ejs-grid component here using the ref
            if (this.modalShow && this.$refs.grid) {
                // Perform actions or operations related to the ejs-grid component
                // For example:
                console.log( this.$refs.grid)
                this.$refs.grid.showSpinner(); // Refresh the grid when the modal is shown
            }
        },
        // Other methods for toolbarClick, exportComplete, actionBegin, onGridActionComplete, etc.
        onPageClick(args) {
            // Update the current page when the user clicks on the pager
            this.filters.page = args.currentPage;
            this.pageSettings.currentPage = args.currentPage;
            this.loading(this.data);
        },
        showLoadingIndicator:() =>{
            let favicon = document.getElementById('dynamic-favicon');
            favicon.href = '/loader.gif';
        },

        hideLoadingIndicator:() => {
            let favicon = document.getElementById('dynamic-favicon');
            favicon.href = '/favicon.ico';
        },
        onGridActionComplete(args) {
            if (args.requestType === 'refresh') {
                swal.close();
                this.hideLoadingIndicator()
                console.log('Grid data has finished loading');
            }
        },
        actionBegin(args) {

            if (args.requestType === 'refresh') {
                this.showLoadingIndicator()
                swal({ title: 'Notice', text: 'Data loading please wait for response.', icon: 'warning' })
                console.log('Grid data loading');
            }
        },
        viewDetails: function(data){
            this.modalTitle = `Clicks Delivered`;
            this.modalShow = true;
            this.date = data.dateTime
            this.loading(data)
        },
        loading:function(data){

            console.log('this is from the loading area')
            if(data){
                console.log(this.$refs.grid)//fills the report array with the needed data
                // this.data = [];

                let dateQuery = data.dateTime

                //this.$refs.griditem.showSpinner = true;


                const request = {
                    'apikey': this.$root.apikey,
                    filters: {
                        start: new Date(),
                        end: new Date()
                    }
                };

                request.filters.start = dateQuery;
                request.filters.end = dateQuery;
                request.pagesize = this.pageSettings.pageSize
                 request.filters.country = data.country
                 request.filters.state = data.state
                 request.filters.city = data.city

                if (this.filters.page) request.filters.page = this.filters.page;
                swal({ title: 'Notice', text: 'Request Sent Please Wait for response.', icon: 'warning' }).then(() => {})
                axios.get(`${this.$root.serverUrl}/admin/clicks/clicksDelivered`, {params: request}).then(function (resp) {
                    //Store the stats
                    let count = (resp.data) ? resp.data.Count : 0;
                    let data = (resp.data) ? resp.data.Items : [];
                    this.total = 0
                    this.counterCheck = count
                    console.log(count)
                    if (resp.data.error) {
                        this.reportLoading = false;
                        swal({title: 'Oops', text: `${resp.data.error.message}`, icon: 'error'})
                    } else {
                        this.reports = data
                        // {result:data, count:count}
                        this.pageSettings.totalRecordsCount = count;
                        this.pageSettings.pageCount = Math.ceil(count / this.pageSettings.pageSize);

                    }
                    this.$refs.griditem.hideSpinner = true;
                }.bind(this)).catch(() => {
                    this.reportLoading = false;
                });

            }
        },
        toolbarClick: function(args = ClickEventArgs) {
            this.$refs.gridItem.showSpinner();
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.gridItem).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.gridItem).pdfExport(this.getPdfExportProperties());
                    break;
                case 'CSV Export':
                    (this.$refs.gridItem).csvExport(this.getCsvExportProperties());
                    break;
            }
        },
        exportComplete() {
            this.$refs.gridItem.hideSpinner();
        },
        getCsvExportProperties: function() {
            return {
                fileName: "exported_data.csv"
            };
        },
        getExcelExportProperties: function() {
            return {
                fileName: "exported_data.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: "exported_data.pdf"
            };
        },
    }
}
</script>

<style scoped>
@import "../../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import '../../node_modules/@syncfusion/ej2-buttons/styles/material.css';

@import "../../node_modules/@syncfusion/ej2-vue-grids/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-navigations/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-calendars/styles/material.css";
@import "../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
.e-grid .e-gridheader {
    position: sticky;
    top: 0px;                                          /* The height of top nav menu. */
    z-index: 1;
}
</style>
