<template>
    <div class="container-fluid">
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Proxy Report
        </h2>
        <h5> Capped clicks, successful clicks and failed clicks to see where our usage is occurring</h5>
        <div class="row">

            <div class="col-lg-2 col-md-3">
                <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                    <h5>View</h5>
                    <!-- <div class="wrap"> -->
                    <div class="form-group mr-1">
                        <select v-model="filters.view" class="form-control border-dark">
                            <option value="default" selected>Default</option>
                            <option v-for="(item,index) in views" :key="index" :value="item.value">{{item.name}}</option>
                        </select>
                    </div>
                    <!-- </div>                                                 -->
                </div>
            </div>
            <div class="col-lg-2 col-md-3">
                <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                    <h5>Date</h5>
                    <div class="wrap">
                        <ejs-datetimepicker cssClass="e-outline" id='startPicker' v-model="filters.from" ></ejs-datetimepicker>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3">
                <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                    <h5>To</h5>
                    <div class="wrap">
                        <ejs-datetimepicker cssClass="e-outline" id='endPicker' v-model="filters.to" ></ejs-datetimepicker>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-md-3">
                <div class="tabs-wrap" style="padding-top: 30px; padding-bottom: 30px">
                    <h5>Providers</h5>
                    <!-- <div class="wrap"> -->
                        <div class="form-group mr-1">
                            <select v-model="filters.provider" class="form-control border-dark">
                                <option value="" selected>-- all --</option>
                                <option v-for="(item,index) in providers" :key="index" :value="item">{{item}}</option>
                            </select>
                        </div>
                    <!-- </div>                                                 -->
                </div>
            </div>
            
            <div class="col-lg-8 col-md-6">
                <div class="row">
<!--                    <div class="col-lg-3 col-md-4">-->
<!--                        <div class="control-styles" style="padding-top: 30px; padding-bottom: 30px">-->
<!--                            <h5>Ip Address</h5>-->
<!--                            <ejs-textbox id="ip" cssClass="e-outline" class="e-field" name="ip" placeholder="127.0.0.1" v-model="filters.ip"></ejs-textbox>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    <div class="col-lg-3 col-md-4">-->
<!--                        <div class="control-styles" style="padding-top: 30px; padding-bottom: 30px">-->
<!--                            <h5>Domain</h5>-->
<!--                            <ejs-textbox id="domain" cssClass="e-outline" class="e-field" name="domain" placeholder="www.newsandpromotions.com" v-model="filters.domain"></ejs-textbox>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="col-lg-2 col-md-2">
                        <div class="tabs-wrap" style="padding-top: 65px; padding-bottom: 30px">
                            <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" style="color: white; background-color: rgb(56, 56, 56);" @click="filter">
                                <span class="e-btn-content">SEARCH</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ejs-grid ref="grid" :dataSource="data" :allowPaging="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :query="query"  :allowFiltering="true" :allowExcelExport="true" :allowPdfExport="true" :allowSorting="true" :pageSettings="pageSettings" :actionFailure='actionFailure' :actionBegin="actionBegin">
            <e-columns v-if="filters.view == 'default'">
                <e-column field="date" headerText="Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'short'}" width="200"></e-column>
                <e-column field="hour" headerText="Hour" text-Align="Left" width="100"></e-column>
                <e-column field="sessions" headerText="Sessions" text-Align="Left"  width="200"></e-column>
                <e-column field="cappedClicks" headerText="Capped Clicks" text-Align="Left" width="200"></e-column>
                <e-column field="cappedDataUsage" headerText="Capped Data Usage" text-Align="Left"  width="200"></e-column>
                <e-column field="successfulClicks" headerText="Successful Clicks" text-Align="Left" width="200"></e-column>
                <e-column field="successfulDataUsage" headerText="Successful Data Usage" text-Align="Left" width="200"></e-column>
                <e-column field="failedClicks" headerText="Failed Clicks" text-Align="Left" width="200"></e-column>
                <e-column field="failedDataUsage" headerText="Failed Data Usage" text-Align="Left" width="200"></e-column>
            </e-columns>
            <e-columns v-if="filters.view == 'summary'">
                <e-column field="date" headerText="Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'short'}" width="200"></e-column>
<!--                <e-column field="hour" headerText="Hour" text-Align="Left" width="100"></e-column>-->
                <e-column field="provider" headerText="Provider" text-Align="Left" width="200"></e-column>
                <e-column field="sessions" headerText="Sessions" text-Align="Left"  width="200"></e-column>
                <e-column field="bandwidth" headerText="Bandwidth" text-Align="Left"  width="200"></e-column>
                <e-column field="shown" headerText="Shown" text-Align="Left" width="200"></e-column>
                <e-column field="capped" headerText="Capped" text-Align="Left" width="200"></e-column>
                <e-column field="shownPercentage" headerText="Shown Percentage %" text-Align="Left" width="200"></e-column>
                <e-column field="bouncePercentage" headerText="Bounce Percentage %" text-Align="Left" width="200"></e-column>
                <e-column field="tos" headerText="Avg TOS" text-Align="Left" width="200"></e-column>
            </e-columns>
            <e-columns v-if="filters.view == 'state'">
                <e-column field="date" headerText="Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'short'}" width="200"></e-column>
<!--                <e-column field="hour" headerText="Hour" text-Align="Left" width="100"></e-column>-->
                <e-column field="provider" headerText="Provider" text-Align="Left" width="200"></e-column>
                <e-column field="state" headerText="State" text-Align="Left" width="200"></e-column>
<!--                <e-column field="city" headerText="City" text-Align="Left" width="200"></e-column>-->
                <e-column field="sessions" headerText="Sessions" text-Align="Left"  width="200"></e-column>
                <e-column field="bandwidth" headerText="Bandwidth" text-Align="Left"  width="200"></e-column>
                <e-column field="shown" headerText="Shown" text-Align="Left" width="200"></e-column>
                <e-column field="capped" headerText="Capped" text-Align="Left" width="200"></e-column>
                <e-column field="shownPercentage" headerText="Shown Percentage %" text-Align="Left" width="200"></e-column>
                <e-column field="bouncePercentage" headerText="Bounce Percentage %" text-Align="Left" width="200"></e-column>
                <e-column field="tos" headerText="Avg TOS" text-Align="Left" width="200"></e-column>
            </e-columns>
            <e-columns v-if="filters.view == 'city'">
                <e-column field="date" headerText="Date" text-Align="Left" :format="{type: 'dateTime', skeleton: 'short'}" width="200"></e-column>
<!--                <e-column field="hour" headerText="Hour" text-Align="Left" width="100"></e-column>-->
                <e-column field="provider" headerText="Provider" text-Align="Left" width="200"></e-column>
                <e-column field="state" headerText="State" text-Align="Left" width="200"></e-column>
                <e-column field="city" headerText="City" text-Align="Left" width="200"></e-column>
                <e-column field="sessions" headerText="Sessions" text-Align="Left"  width="200"></e-column>
                <e-column field="bandwidth" headerText="Bandwidth" text-Align="Left"  width="200"></e-column>
                <e-column field="shown" headerText="Shown" text-Align="Left" width="200"></e-column>
                <e-column field="capped" headerText="Capped" text-Align="Left" width="200"></e-column>
                <e-column field="shownPercentage" headerText="Shown Percentage %" text-Align="Left" width="200"></e-column>
                <e-column field="bouncePercentage" headerText="Bounce Percentage %" text-Align="Left" width="200"></e-column>
                <e-column field="tos" headerText="Avg TOS" text-Align="Left" width="200"></e-column>
            </e-columns>
        </ejs-grid>
    </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import moment from "moment-timezone";
import { DateTimePickerPlugin } from "@syncfusion/ej2-vue-calendars";
import { DropDownListPlugin, ListBoxPlugin } from "@syncfusion/ej2-vue-dropdowns";

//import swal from "sweetalert";
import { GridPlugin, Page, PdfExport, ExcelExport, Sort, Group,Toolbar} from '@syncfusion/ej2-vue-grids';
import { DataManager, WebApiAdaptor, Query } from "@syncfusion/ej2-data";
import { ClickEventArgs } from "@syncfusion/ej2-vue-navigations";

Vue.use(GridPlugin);
Vue.use(DropDownListPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(ListBoxPlugin);

export default {
    name: "proxyreport",
    props: ["user"],
    data: function () {
        return {
            temp: null,
                providers:[],
                views:[
                    // {
                    //     name:'Default',
                    //     value:'default'
                    // },
                    {
                        name:'Summary',
                        value:'summary'
                    },
                    // {
                    //     name:'Coverage',
                    //     value:'coverage'
                    // },
                    {
                        name:'State',
                        value:'state'
                    },
                    {
                        name:'City',
                        value:'city'
                    },
                ],
            filters: {
                from: moment().startOf("day").toDate(),
                to: moment().endOf("day").toDate(),
                domain: "",
                ip: "",
                provider: "",
                view: "default",
            },
            data: null,
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
            preloader: false,
            spinSettings: { duration: 10000000, position: 'Right', width: 20, template: '<div class="template"></div>' }
        };
    },
    computed: {
        query() {
            let filters = Object.assign({}, this.temp);
            return new Query().addParams('filters', JSON.stringify(filters));
        }
    },
    created: function () {
        this.$root.preloader = false;
    },
    mounted: function () {
        this.loadProviders();
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        actionBegin(args) {
            if(args.requestType === "paging") window.scrollTo(0,0);
        },
        actionFailure(args) {
            if (args.error.error.status === 401) this.$auth.logout();
        },
        exportComplete() {
            this.$refs.grid.hideSpinner();
        },
        loadProviders: function(){
            //Load the clients
            var request = {'apikey': this.$root.apikey, provider:this.provider};
            //this.$root.preloader = true;

            return axios.get(`${this.$root.serverUrl}/admin/providers/list`, {params:request}).then(function(resp){
                //Store the stats
                this.providers = (resp.data && resp.data.data)? resp.data.data : [];
                //this.$root.preloader = false;
            }.bind(this));
        },
        toolbarClick: function(args = ClickEventArgs) {

            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    this.$refs.grid.showSpinner();
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    this.$refs.grid.showSpinner();
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties: function() {
            return {
                fileName: "qso_export.xlsx"
            };
        },
        getPdfExportProperties: function() {
            return {

                fileName: "qso_export.pdf"
            };
        },
        windowListener: function (e) {
            if (e.keyCode === 13 && !this.processing) {
                // this.load();
            }
        },
        exportToExcel: function () {
            // this.$root.preloader = true;
            //Load the catchers
            var request = Object.assign({ apikey: this.$root.apikey }, this.form);

            this.eLoading = true;
            // this.preloader = this.$root.preloader = true;

            return axios
                .get(`${this.$root.serverUrl}/static/ga/export`, {
                    params: request,
                    json: true,
                })
                .then(async (resp) => {
                    if (resp == undefined || resp.length == 0) {
                        // this.datasource = { total: 0, data: [] };
                        this.eLoading = false;
                        this.preloader = this.$root.preloader = false;
                    } else {
                        //request = this.serialize(resp);
                        //window.location = `${this.$root.serverUrl}/static/ga/export?${request}`
                        //this.eLoading = false;
                        // this.preloader = this.$root.preloader = false;
                    }
                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                });
        },
        filter() {
            this.$nextTick(function () {
                if (!this.data) {
                    this.data = new DataManager({
                        url: `${this.$root.serverUrl}/admin/proxies/report`,
                        adaptor: new WebApiAdaptor(),
                        crossDomain: true,
                        headers:[{ 'Authorization': 'Bearer ' + this.$cookies.get('accessToken') }]
                    })

                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                }
                let filters = Object.assign({}, this.filters);
                this.temp = filters;
            })
        }
    },
    provide: {
        grid: [Page, Sort,Group,Toolbar,PdfExport,ExcelExport]
    },
};
</script>

<style>
.e-spin-show {
    background: black;
    opacity: 0.5;
}
.e-spinner-inner {
    top: 20% !important;
}
.e-spin-material {
    height: 60px !important;
    width: 60px !important;
    transform-origin: 30px 30px 30px !important;
}
</style>

