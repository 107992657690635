<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-wrench"></i> Bandwidth Analyser
        </h2>
        <div class="row mb-4 justify-content-end">
            <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-4"><div class="card p-4 text-center">Proxied {{ stats.proxied | bandwidth }}</div></div>
                            <div class="col-md-4"><div class="card p-4 text-center">Cached {{ stats.cached | bandwidth }}</div></div>
                            <div class="col-md-4"><div class="card p-4 text-center">Local {{ stats.local | bandwidth }}</div></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <div>Profile Configurator</div>
                        <div v-if="profiles.rules.length" class="float-right">Profile Metrics: {{ reduction.percentage.toFixed(0) || 0 }}%   {{ reduction.bytes | bandwidth}}</div>
                    </div>
                    <div v-if="profiles.rules.length" class="card-body">
                        <table class="table table-responsive table-hover bg-light text-dark">
                            <thead>
                            <tr>
                                <th>Pattern</th>
                                <th>Rule</th>
                                <th>Channel</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(data, index) in profiles.rules" v-bind:key="index">
                                <td>{{ data.pattern }}</td>
                                <td>{{data.rule}}</td>
                                <td>{{data.channel}}</td>
                                <td>
                                    <button class="btn btn-sm" v-b-tooltip.hover.top title="Edit" @click="editProfile(data)" ><span class="fa fa-pencil" title="Analyse"></span></button>
                                    <button class="btn btn-sm" v-b-tooltip.hover.top title="Edit" @click="deleteProfile(index)" ><span class="fa fa-trash" title="Analyse"></span></button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-footer">
                        <div class="row justify-content-end">
                            <div class="col-md-4">
                                <input type="text" v-model="profile.pattern" class="form-control" placeholder="Pattern">
                            </div>
                            <div class="col-md-3">
                                <select v-model="profile.rule" class="form-control">
                                    <option value="contain">Contains</option>
                                    <option value="start">Starts With</option>
                                    <option value="end">Ends With</option>
                                </select>
                            </div>
                            <div class="col-md-3">
                                <select v-model="profile.channel" class="form-control">
                                    <option value="proxy">Proxy</option>
                                    <option value="local">Local</option>
                                </select>
                            </div>
                            <div class="col-md2">
                                <b-button @click="addProfile()" class="ml-2 btn-sm" variant="success" v-text="'Save'" />
                                <b-button v-if="edit" @click="cancel()" class="ml-2 btn-sm" variant="danger" v-text="'Cancel'" />
                            </div>
                            <div v-if="profile.pattern" class="col-md-12">
                                <div class="mt-2 float-right">Pattern Reduction: {{ result.savings | bandwidth}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <table v-if="result.items.length" class="table table-hover bg-light text-dark">
                <thead>
                <tr>
                    <th>Link</th>
                    <th>Type</th>
                    <th>Content Type</th>
                    <th>Channel</th>
                    <th>Bytes</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(data, index) in result.items" v-bind:key="index">
                    <td style="display: block; overflow: hidden; text-overflow: ellipsis">{{ data.url }}</td>
                    <td>{{data.type}}</td>
                    <td>{{data.content_type}}</td>
                    <td>
                        <div v-if="data.proxied == true" class="d-flex align-content-center text-danger">proxied</div>
                        <div v-if="data.cached == true" class="d-flex align-content-center text-success">cached</div>
                        <div v-if="data.local == true" class="d-flex align-content-center text-success">warning</div>
                    </td>
                    <td>{{data.bytes | bandwidth}}</td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";

import swal from "sweetalert";
import moment from "moment-timezone";

export default {
    name: "BandwidthAnalyser",
    props: ["user"],
    data: function () {
        return {
            edit: false,
            savings: 0,
            profile: {rule: "contain", channel: "local"},
            items: [],
            profiles: {rules: [], domain: this.$route.query.domain},
            stats: {
                proxied: 0,
                cached: 0,
                local: 0
            }
        };
    },
    computed: {
        reduction() {
            if (!this.profiles.rules) return { percentage: 0, bytes: 0}

            let reg = "";
            for (let i = 0; i < this.profiles.rules.length; i++) {
                if (i > 0) reg += "|"

                if (this.profiles.rules[i].channel !== "proxy") {
                    switch(this.profiles.rules[i].rule) {
                        case "contain":
                            reg += this.profiles.rules[i].pattern
                            break;
                        case "start":
                            reg += "^" + this.profiles.rules[i].pattern
                            break;
                        case "end":
                            reg += this.profiles.rules[i].pattern + "$"
                            break
                        default:
                            reg += this.profiles.rules[i].pattern
                    }
                }
            }
            reg = new RegExp(reg, "ig");

            let total = 0
            let savings = 0

            console.log(reg)

            this.items.filter((item) => {
                if (item.proxied == true) {
                    total += item.bytes;
                    if (reg.test(item.url)) {
                        savings = savings + item.bytes
                        return item;
                    }
                }
            });

            return  { percentage: (savings / total) * 100, bytes: savings}
        },
        result() {
          if (!this.profile.pattern) return { items: this.items, savings: 0}

          let reg;
          switch(this.profile.rule) {
              case "contain":
                  reg = new RegExp(this.profile.pattern, "ig")
                  break;
              case "start":
                  reg = new RegExp("^" + this.profile.pattern, "ig");
                  break;
              case "end":
                  reg = new RegExp(this.profile.pattern + "$", "ig");
                  break
              default:
                  reg = new RegExp(this.profile.pattern, "ig")
          }

          let savings = 0

          let items = this.items.filter((item) => {
              if (reg.test(item.url)) {
                  if (this.profile.rule.channel !== "proxy") savings = savings + item.bytes
                  return item;
              }
          });

          return { items: items, savings: savings}
        }
    },
    created: function () {
        this.search()
    },
    beforeDestroy() {},
    destroyed() {
        window.removeEventListener("keydown", this.windowListener);
    },
    methods: {
        deleteProfile(index) {
            let request = {...this.profiles};

            request.rules.splice(index);

            axios.post(`${this.$root.serverUrl}/admin/bandwidth/profile`, request).then((resp) => {
                if ( resp.data ) {
                    this.edit = false;
                    this.profiles = request;
                    this.profile = {rule: "contain", channel: "local"};
                }
            }).catch((err) => {
                console.log(err);
                swal({title: 'Oops', text: err.message, icon: 'error'})
            });
        },
        editProfile(data, index) {
            this.profile = Object.assign({}, data);
            this.edit = index + 1;
            this.$forceUpdate();
        },
        cancel() {
            this.edit = false;
            this.profile = {rule: "contain", channel: "local"};
        },
        addProfile() {
            let request = {...this.profiles};

            if (this.edit) {
                request[this.edit - 1] = {rule: this.profile.rule, pattern: this.profile.pattern, channel: this.profile.channel}
            }
            else request.rules.push({rule: this.profile.rule, pattern: this.profile.pattern, channel: this.profile.channel})

            axios.post(`${this.$root.serverUrl}/admin/bandwidth/profile`, request).then((resp) => {
                if ( resp.data ) {
                    this.edit = false;
                    this.profiles = request;
                    this.profile = {rule: "contain", channel: "local"};
                }
            }).catch((err) => {
                console.log(err);
                swal({title: 'Oops', text: err.message, icon: 'error'})
            });
        },
        search() {
            var request = { filters: {} };
            if (this.$route.query.domain) request.filters.domain = this.$route.query.domain;
            if (this.$route.query.date) {
                request.filters.start = moment(this.$route.query.date).startOf('day').toDate();
                request.filters.end = moment(this.$route.query.date).endOf('day').toDate();
            }

            axios.get(`${this.$root.serverUrl}/admin/bandwidth/analyse`, {params: request}).then((resp) => {
                if(resp.data){
                    this.profiles = resp.data.profile || {rules: [], domain: this.$route.query.domain};
                    this.stats = resp.data.stats;
                    this.items = resp.data.data;
                    this.$root.preloader = false;
                }
            }).catch((err) => {
                console.log(err);
                swal({title: 'Oops', text: err.message, icon: 'error'})
            });
        }
    }
};
</script>

<style>

</style>
